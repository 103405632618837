import React from 'react';
import {Contact } from '@components';
import {Grid, Typography} from '@material-ui/core';
import {FetchAllParksDocument, FetchAllParksQuery, FetchAllParksQueryVariables} from './queries.generated';
import {useQuery} from '@apollo/client';
import {RouteLabels, Routes} from '@constants';
import {Venue} from '@generated/schema';
import {VenueCard, LoadingComponent, ErrorComponent, PageTitle} from "@queensland-running/qr-components";
import {useTranslation} from "react-i18next";

const ParksView = () => {
  const { t} = useTranslation('common')
  const {data, loading, error} = useQuery<FetchAllParksQuery, FetchAllParksQueryVariables>(FetchAllParksDocument);

  if (loading) return <LoadingComponent/>;
  if (error) return <ErrorComponent/>;

  const venues: Venue[] = (data!.venues || []) as Venue[];
  return (
    <>
      <PageTitle title={t('app.routes.venues')}/>

      <Typography gutterBottom>
        Queensland Running holds events at a variety of venues on Brisbane's northside and southside. We are grateful
        for the assistance provided by the Brisbane City Council and Moreton Bay Regional Council in making these venues
        available for our weekly events.
      </Typography>
      <Typography gutterBottom>
        We encourage family, friends and other supporters to submit information, photos and reviews of the courses, to
        add to that already provided on each of the venue pages.
      </Typography>
      <Typography gutterBottom>
        Please email <Contact name="qr" type="emailAddress"/> with any suggestions.
      </Typography>
      <Grid container spacing={3}>
        {venues.map((venue: Venue) => (
          <Grid item xs={12} sm={6} md={4} key={venue.url || ''}>
            <VenueCard {...venue} to={`${Routes.venues}/${venue.url}`}/>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export const Venues = React.memo(ParksView);
