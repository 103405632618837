/* eslint-disable */
import * as Types from '../../../@generated/schema.d';

import { gql } from 'graphql.macro';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHoc from '@apollo/client/react/hoc';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FetchAccountQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FetchAccountQuery = { readonly __typename?: 'Query' } & {
  readonly userAccount?: Types.Maybe<
    { readonly __typename?: 'UserAccount' } & Pick<
      Types.UserAccount,
      'id' | 'email' | 'firstName' | 'lastName' | 'phoneNumber' | 'address' | 'postalAddress' | 'postcode' | 'suburb'
    >
  >;
};

export const FetchAccountDocument = gql`
  query fetchAccount {
    userAccount {
      id
      email
      firstName
      lastName
      phoneNumber
      address
      postalAddress
      postcode
      suburb
    }
  }
`;
export type FetchAccountComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<FetchAccountQuery, FetchAccountQueryVariables>,
  'query'
>;

export const FetchAccountComponent = (props: FetchAccountComponentProps) => (
  <ApolloReactComponents.Query<FetchAccountQuery, FetchAccountQueryVariables> query={FetchAccountDocument} {...props} />
);

export type FetchAccountProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<FetchAccountQuery, FetchAccountQueryVariables>;
} &
  TChildProps;
export function withFetchAccount<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FetchAccountQuery,
    FetchAccountQueryVariables,
    FetchAccountProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FetchAccountQuery,
    FetchAccountQueryVariables,
    FetchAccountProps<TChildProps, TDataName>
  >(FetchAccountDocument, {
    alias: 'fetchAccount',
    ...operationOptions,
  });
}

/**
 * __useFetchAccountQuery__
 *
 * To run a query within a React component, call `useFetchAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchAccountQuery, FetchAccountQueryVariables>,
) {
  return Apollo.useQuery<FetchAccountQuery, FetchAccountQueryVariables>(FetchAccountDocument, baseOptions);
}
export function useFetchAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchAccountQuery, FetchAccountQueryVariables>,
) {
  return Apollo.useLazyQuery<FetchAccountQuery, FetchAccountQueryVariables>(FetchAccountDocument, baseOptions);
}
export type FetchAccountQueryHookResult = ReturnType<typeof useFetchAccountQuery>;
export type FetchAccountLazyQueryHookResult = ReturnType<typeof useFetchAccountLazyQuery>;
export type FetchAccountQueryResult = Apollo.QueryResult<FetchAccountQuery, FetchAccountQueryVariables>;
