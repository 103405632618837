import {useState} from "react";
import v4 from "uuid/v4";
import {useQueenslandRunningAPI} from "@utils/client";

const useRegister = () => {
    const {register} = useQueenslandRunningAPI()
    const [registered, setRegistered] = useState<boolean>(false);
    const [error, setError] = useState();

    const onSubmit = ({event, contact, runners}: any) => {
        console.log('OnClickSubmit', 'register', event, contact, runners);
        register({
            id: v4(),
            event,
            contact,
            runners
        }).then((d) => {
            setRegistered(true)
            console.log(d);
        }).catch(error => {
            setError(error);
        })
    }

    return {
        registered,
        onSubmit
    }
}

export default useRegister;

