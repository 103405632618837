/* eslint-disable */
import * as Types from '../../../@generated/schema.d';

import { gql } from 'graphql.macro';
export type WeeklyRegistrationRegistrationFragment = { readonly __typename?: 'WeeklyCompetitionRegistration' } & {
  readonly contactDetails?: Types.Maybe<
    { readonly __typename?: 'ContactDetails' } & Pick<Types.ContactDetails, 'firstName' | 'lastName'>
  >;
};

export type RockettRelaysRegistrationFragment = { readonly __typename?: 'RockettRelaysRegistration' } & {
  readonly contactDetails?: Types.Maybe<
    { readonly __typename?: 'ContactDetails' } & Pick<Types.ContactDetails, 'firstName' | 'lastName'>
  >;
  readonly teamDetails?: Types.Maybe<
    { readonly __typename?: 'TeamDetails' } & Pick<Types.TeamDetails, 'teamName' | 'relay' | 'gender' | 'ageGroup'>
  >;
  readonly runnerDetails?: Types.Maybe<
    ReadonlyArray<
      { readonly __typename?: 'RockettRelaysRunnerDetails' } & Pick<
        Types.RockettRelaysRunnerDetails,
        'dateOfBirth' | 'name' | 'gender' | 'position'
      >
    >
  >;
};

export const WeeklyRegistrationRegistrationFragmentDoc = gql`
  fragment weeklyRegistrationRegistration on WeeklyCompetitionRegistration {
    contactDetails {
      firstName
      lastName
    }
  }
`;
export const RockettRelaysRegistrationFragmentDoc = gql`
  fragment rockettRelaysRegistration on RockettRelaysRegistration {
    contactDetails {
      firstName
      lastName
    }
    teamDetails {
      teamName
      relay
      gender
      ageGroup
    }
    runnerDetails {
      dateOfBirth
      name
      gender
      position
    }
  }
`;
