import React from 'react';

const FacebookPage = () => (
  <div
    className="fb-page"
    data-href="https://www.facebook.com/queenslandrunning"
    data-tabs="timeline"
    data-width="500px"
    data-height=""
    data-small-header="true"
    data-adapt-container-width="true"
    data-hide-cover="false"
    data-show-facepile="false">
    <blockquote cite="https://www.facebook.com/queenslandrunning" className="fb-xfbml-parse-ignore">
      <a href="https://www.facebook.com/queenslandrunning">Queensland Running</a>
    </blockquote>
  </div>
);

export { FacebookPage };
