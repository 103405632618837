import React from 'react';
import { compose } from 'recompose';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { Contact } from '@components';
import { ProgramItem } from '@generated/schema';
import { ProgramTable, Blockquote } from '@queensland-running/qr-components';
import { Section } from '@components/section/section';

const rockettRelays2022OneHourEntryFormUrl = '/assets/pdfs/forms/1 Hour Relay Team Entry 2022.pdf';
const rockettRelays2022TwoHourEntryFormUrl = '/assets/pdfs/forms/2 Hour Relay Team Entry 2022.pdf';
const sampleTallyOneHourFormUrl = '/assets/pdfs/forms/Rockett-Relays-Team-Tally-Sheet-One-Hour-Sample.pdf';
const sampleTallyTwoHourFormUrl = '/assets/pdfs/forms/Rockett-Relays-Team-Tally-Sheet-Two-Hour-Sample.pdf';

export const rockettRelays: ProgramItem[] = [
  {
    __typename: 'ProgramItem',
    time: '1:00 pm',
    ageGroup: ['10 Years and Under', '14 Years and Under'],
    race: '1 Hour Relay',
  },
  {
    __typename: 'ProgramItem',
    time: '1:10 pm',
    ageGroup: ['Any'],
    race: '500m',
  },
  {
    __typename: 'ProgramItem',
    time: '1:20 pm',
    ageGroup: ['Any'],
    race: '2000m',
  },
  {
    __typename: 'ProgramItem',
    time: '1:30 pm',
    ageGroup: ['Open', 'Masters (40+)'],
    race: '2 Hour Relay',
  },
  {
    __typename: 'ProgramItem',
    time: '2:15 pm',
    ageGroup: ['Any'],
    race: '1000m',
  },
];

const enhance = compose(React.memo);

const RegisterView = () => {
  return (
    <>
      <Typography variant="h5" color={'secondary'}>
        Program
      </Typography>
      <ProgramTable programItems={rockettRelays} />
      <br />

      <Section verticalSpacing>
        <Typography>
          The afternoon commences earlier at 1.00 p.m. with the One Hour Relay for runners 14 years and under and the
          Two Hour Relay starts at 1.30 p.m. As well as the relays, there are three races for those runners who wish to
          run just the normal program. At 1.10 p.m. there is a 500m, followed by a 2000m at 1.20 p.m., finishing with a
          1000m race at 2.15 p.m. This race is open to those runners who have also run the relays and want a little
          more!
        </Typography>
        <Typography>Teams require a lapscorer and clipboard for the afternoon.</Typography>
        <Typography>
          If you require further information please contact <Contact type="phoneNumber" name="qr" /> or email{' '}
          <Contact type="emailAddress" name="qr" />
        </Typography>
      </Section>

      <Section verticalSpacing>
        <Typography variant="h3" color="primary">
          One Hour Relay
        </Typography>
        <Typography>
          <b>Maximum of three runners,</b> rotating on a 1000m course. Open to runners aged 14 years and under (on the
          day). Two age group categories: 10 and under, 14 and under.
        </Typography>
        <Typography variant="h3" color="primary">
          Two Hour Relay
        </Typography>
        <Typography>
          <b>Maximum of four runners,</b> rotating on a 2000m course. The last 15 minutes of the two hour relay will be
          on the 1000m course. Open to runners aged 15 years and over (on the day). Two age group categories: open and
          masters (40+).
        </Typography>
        <Typography>
          <b>Note re mixed teams:</b>
        </Typography>
        <Typography>Mixed gender teams are considered male teams</Typography>
        <Typography>Mixed age teams in one hour relay are considered 14 and under teams</Typography>
        <Typography>Mixed age teams in two hour relay are considered Open teams</Typography>
      </Section>

      <Section verticalSpacing>
        <Typography variant="h3" color="primary">
          Entry Fees
        </Typography>
        <Typography variant="h5" color="secondary">
          Fees
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Race</TableCell>
              <TableCell>Regular Weekly Competitors</TableCell>
              <TableCell>Other Competitors</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>1 Hour Relay</TableCell>
              <TableCell>$20.00* per team</TableCell>
              <TableCell>$30.00 per team</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>2 Hour Relay</TableCell>
              <TableCell>$35.00* per team</TableCell>
              <TableCell>$45.00 per team</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <br />
        <Typography variant="subtitle2">
          * These entry fees apply to regular weekly competitors only. Other competitors will be required to pay an
          additional fee.
        </Typography>
        <Typography>
          All entry fees go into the prize pool plus a contribution from Queensland Running.
        </Typography>
        <Typography>
          Entry forms and tally sheets are available below.{' '}
          <b>Please arrive at least one hour before the event is due to start to lodge your entry.</b>
        </Typography>
        <Typography>
          The entry form is to be submitted to register your team. It is used while the relay is in progress to
          determine prize money.
        </Typography>
        <Typography>
          The tally sheet is to be used by team recorders during the race and then presented to the results table for
          processing upon completion of the race. Recorders are reminded to <b>bring a clipboard</b> to record your
          team's laps during the relay.
        </Typography>
        <Typography variant="h5" color="secondary">
          Team Entry Forms
        </Typography>
        <Typography component="a" href={rockettRelays2022OneHourEntryFormUrl} display="block" target="_blank">
          2022 Team Entry Form - One Hour
        </Typography>
        <Typography component="a" href={rockettRelays2022TwoHourEntryFormUrl} display="block" target="_blank">
          2022 Team Entry Form - Two Hour
        </Typography>
        <Typography variant="h5" color="secondary">
          Sample Team Tally Sheets
        </Typography>
        <Typography component="a" href={sampleTallyOneHourFormUrl} display="block" target="_blank">
          Sample Team Tally Sheet - One Hour
        </Typography>
        <Typography component="a" href={sampleTallyTwoHourFormUrl} display="block" target="_blank">
          Sample Team Tally Sheet - Two Hour
        </Typography>

      </Section>
      <Section verticalSpacing>
        <Typography variant="h3" color="primary">
          Event History
        </Typography>
        <Typography>
          The concept of cross-country relays is unknown to us but <b>Bob Rockett</b> often spoke of 12 hour and 24 hour
          relays as once being fashionable. Unlike track and field relays, team runners were required to run alternating
          laps of an appropriate distance on a repetition basis until the set time had elapsed. The team which covered
          the furthest distance in the elapsed time was declared the winner.
        </Typography>
        <Typography>
          Bob reasoned that such an event could easily be adapted to fit the Queensland Running format and provide our
          members with an opportunity to respond to new challenges within a reasonable time limit, an opportunity to
          simulate rep training sessions and to experience a self-managed team environment which gave them scope to make
          tactical decisions as the race unfolded.
        </Typography>
        <Typography>
          To put thought into practice, Bob's club, Pine Rivers Northern Suburbs Athletic Club provided prize money for
          two events, a One Hour Relay for younger runners in teams of no more than three and a Two Hour Relay for more
          mature athletes comprised of teams of no more than four runners. Teams would pay an entry fee from which a
          percentage was added to the prize pool.
        </Typography>
        <Typography>
          Queensland Running (which now provides the funding) and in recognition of <b>Bob Rockett</b> as a former
          athlete, club founder, successful coach of many youth athletes, a prime-mover in the formation of Queensland
          Running and a credit to his much-beloved sport, have named both events in his honour.
        </Typography>
      </Section>
    </>
  );
};

export const RockettRelays = enhance(RegisterView);
