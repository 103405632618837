import React from 'react';
import {env} from '@constants';
import {hydrate, render} from 'react-dom';
import Amplify from 'aws-amplify';
import './index.css';
import * as serviceWorker from './serviceWorker';
import AuthApp from './AuthApp';
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import common_en from "@constants/language/common";

Sentry.init({
  dsn: env.sentryDsn,
  integrations: [new Integrations.BrowserTracing()],
  environment: env.stage,
  tracesSampleRate: 0.2,
});


const rootElement = document.getElementById('root');

if (!rootElement) {
  throw new Error('Root element not found; App needs an entry point!');
}

Amplify.configure({
  Auth: {
    region: env.region,
    userPoolId: env.userPoolId,
    userPoolWebClientId: env.userPoolWebClientId,
  },
});

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping,
  lng: 'en',
  resources: {
    en: {
      common: common_en
    },
  },
});

if (rootElement.hasChildNodes()) {
  hydrate(<I18nextProvider i18n={i18next}><AuthApp /></I18nextProvider>, rootElement);
} else {
  render(<I18nextProvider i18n={i18next}><AuthApp /></I18nextProvider>, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
