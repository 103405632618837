import React from "react";
import {ContactType} from "./types";
import contacts from '@constants/contact'

type ContactMethod<T extends keyof typeof contacts> = keyof Omit<typeof contacts[T], 'name'>

interface ContactProps<T extends keyof typeof contacts> {
  name: T
  type: ContactMethod<T>
}

export const Contact = <T extends keyof typeof contacts>({type, name}: ContactProps<T>) => {
  const contact: ContactType = contacts[name];

  if (type === 'emailAddress' && contact.emailAddress) {
    return <a href={`mailto: ${contact.emailAddress}`}>{contact.name}</a>
  }
  if (type === 'phoneNumber' && contact.phoneNumber) {
    return <span><a href={`tel: ${contact.phoneNumber}`}>{contact.phoneNumber}</a></span>
  }
  return null;
};