import React, { createContext, ReactNode, useContext, useState } from 'react';

const RegistrationContext = createContext<RegistrationContext>({
  registrationId: '',
  setRegistrationId: () => {},
  orderId: '',
  setOrderId: () => {},
  locationId: '',
  // setLocationId: () => {},
  applicationId: '',
  // setApplicationId: () => {},
  competitionRegistration__typename: undefined,
  setCompetitionRegistration__typename: () => {}
});

type CompetitionRegistration__typename = 'WeeklyCompetitionRegistration' | 'RockettRelaysRegistration' | undefined;

export interface RegistrationContext {
  registrationId?: string;
  setRegistrationId: (registrationId: string | undefined) => void;
  orderId?: string;
  setOrderId: (orderId: string | undefined) => void;
  locationId: string;
  // setLocationId: (locationId: string | undefined) => void;
  applicationId: string;
  // setApplicationId: (applicationId: string | undefined) => void;
  competitionRegistration__typename: CompetitionRegistration__typename
  setCompetitionRegistration__typename: (__typename: CompetitionRegistration__typename) => void
}

export const useRegistration = () => {
  const config = useContext(RegistrationContext);

  if (!config) {
    throw new Error('Configuration should already be loaded');
  }

  return config;
};

const RegistrationProvider = ({ children }: { children: ReactNode }) => {
  const [registrationId, setRegistrationId] = useState<string | undefined>();
  const [orderId, setOrderId] = useState<string | undefined>();
  const [locationId] = useState<string>('L887AN61MPQSG');
  const [applicationId] = useState<string>('sandbox-sq0idb-quwDmnfPdh8nD6H-6yqFNA');
  const [competitionRegistration__typename, setCompetitionRegistration__typename] = useState<CompetitionRegistration__typename>(undefined);
  return <RegistrationContext.Provider value={{
    registrationId,
    setRegistrationId,
    orderId,
    setOrderId,
    locationId,
    applicationId,
    competitionRegistration__typename,
    setCompetitionRegistration__typename
  }}>{children}</RegistrationContext.Provider>;
};

export default RegistrationProvider;
