import React, { useState } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Button, Grid, Typography } from '@material-ui/core';
import { useRunnersQuery } from './queries.generated';
import { Runner } from '@generated/schema';
import { ButtonWrapper, LoadingComponent, PageTitle, RegisteredRunnersTable } from '@queensland-running/qr-components';
import { CreateRunnerModal } from '../../modals/create-runner-modal';
import { UpdateRunnerModal } from '../../modals/update-runner-modal';
import { Contact } from '@components/contacts';
import { Section } from '@components/section/section';
import {RouteLabels} from "@constants";

type RegisterProps = {};

type RunnersViewProps = {};

const enhance = compose<RunnersViewProps, RegisterProps>(withRouter, React.memo);

const RunnersView = () => {
  const [createRunnerModalOpen, setCreateRunnerModalOpen] = useState(false);
  const [runners, setRunners] = useState<Runner[]>([]);
  const [runner, setRunner] = useState<Runner | undefined>(undefined);
  const { loading } = useRunnersQuery({
    onCompleted: (data) => {
      // @ts-ignore
      setRunners(data && data.runners && data.runners.data || []);
    },
  });

  if (loading) {
    return <LoadingComponent />;
  }

  const pendingRunners = runners.filter(({ status }) => status === 'PENDING').length;
  const overPendingRunnerLimit = pendingRunners > 12;
  return (
    <>
      <PageTitle title={RouteLabels.runners}/>

      <Grid container>
        <Grid item xs={12}>
          <Section verticalSpacing>
            <Typography>
              Welcome to <i>Runners</i>. On this page, you can register runners who run with you on a regular basis.
            </Typography>
            <Typography>
              When you add a runner, there is a verification process which may take up to 48 hours. During this process,
              you can still register for events. To assist us
              with this verification process, please provide as much information as possible.
            </Typography>
            <Typography>
              Any runners listed below will appear on the Event registration page. If you intend on running, be sure to add yourself below.
            </Typography>
            <Typography>
              Please email <Contact name={'jolsen'} type={'emailAddress'} /> with any amendments you require.
            </Typography>
          </Section>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <Typography variant="overline" color="secondary">
              Actions
            </Typography>
            <br />
            <ButtonWrapper>
              <Button
                disabled={overPendingRunnerLimit}
                color="primary"
                type="submit"
                variant="contained"
                onClick={() => setCreateRunnerModalOpen(true)}>
                Add a runner
              </Button>
            </ButtonWrapper>
            {overPendingRunnerLimit && (
              <Typography>Let us verify some runners before you can add new runners.</Typography>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {runners && <RegisteredRunnersTable runners={runners} registrations={false} paginate={false} />}
      </Grid>

      <CreateRunnerModal
        open={createRunnerModalOpen}
        onClose={() => setCreateRunnerModalOpen(false)}
        setData={(newRunner) => setRunners([...runners, newRunner])}
      />

      {runner!! && (
        <UpdateRunnerModal
          open={runner !== undefined}
          onClose={() => setRunner(undefined)}
          setData={(updatedRunner: Runner) => {
            const updatedRunnerIndex = runners.findIndex(({ id }) => id === updatedRunner.id);
            const cloned = [...runners];
            cloned[updatedRunnerIndex] = { ...cloned[updatedRunnerIndex], ...updatedRunner };
            return setRunners([...cloned]);
          }}
          runner={runner}
        />
      )}
    </>
  );
};

export const Runners = enhance(RunnersView);
