/* eslint-disable */
import * as Types from '../../../@generated/schema.d';

import { gql } from 'graphql.macro';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHoc from '@apollo/client/react/hoc';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RunnersQueryVariables = Types.Exact<{ [key: string]: never }>;

export type RunnersQuery = { readonly __typename?: 'Query' } & {
  readonly runners?: Types.Maybe<
    { readonly __typename?: 'RunnersQueryResponse' } & {
      readonly data: ReadonlyArray<
        { readonly __typename?: 'Runner' } & Pick<
          Types.Runner,
          'id' | 'bibNumber' | 'firstName' | 'lastName' | 'dateOfBirth' | 'club' | 'gender' | 'status' | 'membership'
        >
      >;
    }
  >;
};

export const RunnersDocument = gql`
  query runners {
    runners {
      data {
        id
        bibNumber
        firstName
        lastName
        dateOfBirth
        club
        gender
        status
        membership
      }
    }
  }
`;
export type RunnersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<RunnersQuery, RunnersQueryVariables>,
  'query'
>;

export const RunnersComponent = (props: RunnersComponentProps) => (
  <ApolloReactComponents.Query<RunnersQuery, RunnersQueryVariables> query={RunnersDocument} {...props} />
);

export type RunnersProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<RunnersQuery, RunnersQueryVariables>;
} &
  TChildProps;
export function withRunners<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RunnersQuery,
    RunnersQueryVariables,
    RunnersProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<TProps, RunnersQuery, RunnersQueryVariables, RunnersProps<TChildProps, TDataName>>(
    RunnersDocument,
    {
      alias: 'runners',
      ...operationOptions,
    },
  );
}

/**
 * __useRunnersQuery__
 *
 * To run a query within a React component, call `useRunnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunnersQuery({
 *   variables: {
 *   },
 * });
 */
export function useRunnersQuery(baseOptions?: Apollo.QueryHookOptions<RunnersQuery, RunnersQueryVariables>) {
  return Apollo.useQuery<RunnersQuery, RunnersQueryVariables>(RunnersDocument, baseOptions);
}
export function useRunnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RunnersQuery, RunnersQueryVariables>) {
  return Apollo.useLazyQuery<RunnersQuery, RunnersQueryVariables>(RunnersDocument, baseOptions);
}
export type RunnersQueryHookResult = ReturnType<typeof useRunnersQuery>;
export type RunnersLazyQueryHookResult = ReturnType<typeof useRunnersLazyQuery>;
export type RunnersQueryResult = Apollo.QueryResult<RunnersQuery, RunnersQueryVariables>;
