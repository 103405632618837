import {AppNav} from '@containers';
import React from 'react';
import {compose, withProps} from 'recompose';
import {BrowserRouter as Router} from 'react-router-dom';
import {ApolloClient, ApolloProvider, NormalizedCacheObject} from '@apollo/client';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {Container} from '@material-ui/core';
import styled from 'styled-components';
import {createClient} from 'graphql-client';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {AppFooter} from '@containers/partials/app-footer';
import ConfigProvider from '@constants/config';
import {SnackbarProvider} from 'notistack';
import {AppRoutes} from '@containers/routes';
import {theme, ThemeProvider} from "@queensland-running/qr-components";
import {setupThirdParties} from "@components/social/setup-third-parties";
import EventProvider from "./@constants/event-selector";
import RegistrationProvider from '@constants/registration-context';
import QueenslandRunningAPIProvider from "./@utils/client";

type AppProps = {};
type AppViewProps = AppProps & {
  client: ApolloClient<NormalizedCacheObject>;
};

const enhance = compose<AppViewProps, AppProps>(
  withProps<{}, AppViewProps>(() => {
    return {
      client: createClient(),
    };
  }),
  React.memo,
);

const StyledContainer = styled(Container)`
  @media (min-width: ${({theme}) => theme.breakpoints.values.xs}px) {
    padding-top: 4rem;
    padding-bottom: 30rem;
  }

  @media (min-width: ${({theme}) => theme.breakpoints.values.md}px) {
    padding-top: 5rem;
    padding-bottom: 20rem;
  }
`;

// const onRedirectCallback = (appState: any) => {
//   history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
// };

setupThirdParties()

const AppView = ({client}: AppViewProps) => {
  return (
    <ConfigProvider>
      <ApolloProvider client={client}>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ThemeProvider theme={theme}>
              <QueenslandRunningAPIProvider>
              <EventProvider>
              <RegistrationProvider>
              <Router>
                <div className="App" style={{position: 'relative', minHeight: '100vh'}}>
                  <AppNav/>
                  <StyledContainer>
                    <SnackbarProvider>
                      <AppRoutes/>
                    </SnackbarProvider>
                  </StyledContainer>
                  <AppFooter/>
                </div>
              </Router>
              </RegistrationProvider>
              </EventProvider>
              </QueenslandRunningAPIProvider>
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </ApolloProvider>
    </ConfigProvider>
  );
};

export const App = enhance(AppView);
