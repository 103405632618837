import {useEffect, useState} from 'react';
import {useFetchCompetitionsQuery} from '@containers/pages/event/queries.generated';
import {useConfig} from '@constants/config';
import {usePrograms} from "./use-programs";

const useCalendar = () => {
    const {
        constants: {currentYear},
    } = useConfig();
    const {toggles} = useConfig()
    const [year, setYear] = useState<string>(currentYear);
    const [calendar, setCalendar] = useState<any[]>([]);
    const {getProgram} = usePrograms();
    const {data, loading, error} = useFetchCompetitionsQuery({
        variables: {
            year
        },
    });

    useEffect(() => {
        if (toggles.v2) {
            fetch(`http://localhost:9876/dev/calendar/${year}`,
                {
                    headers: {
                        'x-api-key': 'd41d8cd98f00b204e9800998ecf8427e'
                    }
                })
                .then((response) => response.json())
                .then((data) => {
                    setCalendar(data);
                });
        }
    }, [year, toggles.v2])

    // @ts-ignore
    const apiYears = data?.competitions?.availableYears;
    const availableYears = (['2024', '2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016']).map((year: string): any => ({
        key: year,
        text: year,
        value: year,
        onClick: () => setYear(year),
    }));

    return {
        loading,
        error,
        setYear,
        calendar: {
            events: data?.competitions?.competitions ?? [],
        },
        calendarV2: {
            version: 2,
            //@ts-ignore
            events: (calendar?.events ?? []).map((c) => {
                return ({
                    ...c,
                    __typename: c.type,
                    day: c.date,
                    agenda: c.title,
                    results: c.results?.ref,
                    programItems: getProgram(c.program || c.agenda)
                });
            }),
        },
        availableYears,
        currentYear: year,
    };
};

export {useCalendar};
