import React from 'react';
import {compose} from 'recompose';
import queryString from 'query-string';
import {Grid, Typography} from '@material-ui/core';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {FetchCompetitionQuery, useFetchCompetitionQuery} from './queries.generated';

import {mapRoute, RouteKeys} from '@constants/routes';
import styled from 'styled-components';
import {ProgramItem,} from '@generated/schema';
import fallbackImg from '@images/fallback/parks.jpg';
import {
    ErrorComponent,
    EventDate,
    EventProgram,
    EventVenue,
    LoadingComponent,
    PageTitle,
    ProgramTable,
    Results,
    VenueCard,
} from '@queensland-running/qr-components';
import {toRoutes} from '@utils/mapper';
import {usePrograms, withErrorBoundary} from '@components/higher-order';
import EventRegistrationFlow from "../competition-registration/event-registration-flow";
import {toggles} from "@constants/features";
import {useTranslation} from "react-i18next";
import { CompetitionType } from '../competition-registration/multi-registration-flow';
import {EventDetails} from "./event-details";
import {CompetitionForms} from "./event-forms";
import useRegister from "./useRegister";
import {SingleRegistration} from "../competition-registration/single-registration-flow";

const SideGrid = styled(Grid)(({theme}) => ({
    '&&&': {
        [theme.breakpoints.only('sm')]: {
            '> .MuiGrid-item': {
                padding: '12px',
            },
            margin: '-12px',
        },
        [theme.breakpoints.up('xs')]: {
            height: 'fit-content',
            '> .MuiGrid-item:not(:last-child)': {
                marginBottom: '1rem',
            },
        },
    },
}));


type QueryParams = {
    eventId?: string;
    competitionId?: string;
};

const enhance = compose(withErrorBoundary, withRouter, React.memo);

const EventRegistration = ({competition}: FetchCompetitionQuery) => {
    const { onSubmit } = useRegister();

    return <SingleRegistration onSubmit={onSubmit} details={() => {
        return null
    }} competition={competition} />;
}

const RegisterView = ({location: {search}, history}: RouteComponentProps) => {
    const {t} = useTranslation('common')
    const { getProgram } = usePrograms()
    const competitionId = (queryString.parse(search) as QueryParams).competitionId;
    const {data, loading} = useFetchCompetitionQuery({
        errorPolicy: 'all',
        variables: {
            id: competitionId!,
        },
        fetchPolicy: 'network-only',
    });
    if (!competitionId) {
        return null;
    }
    if (loading) {
        return <LoadingComponent/>;
    }

    // @ts-ignore
    const {competition} = data;
    const programItems = getProgram((competition as CompetitionType)?.agenda) as ProgramItem[]

    if (competition) {
        if (competition.parkKey === 'No Competition') {
            history.replace({
                pathname: RouteKeys.events,
            });
        }

        return (
            <>
                <PageTitle
                    title={t('app.routes.competitionDetails', {replace: {competition: competition.agenda ?? 'Competition Details'}})}
                    breadcrumbs={toRoutes([RouteKeys.calendar])}
                />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                        <EventDate day={competition.day} variant="subtitle1"/>
                        <EventVenue venue={competition.venue || {displayName: 'TBC'}} variant="subtitle1"
                                    parentPath={'/venues/'}/>
                        <EventProgram program={competition.program} variant={'subtitle1'}/>
                        <br/>

                        {/*{competition.id.includes('2022') && */}
                            <EventDetails agenda={competition.agenda}/>
                        {/*}*/}

                        {
                            toggles.registration && <EventRegistration competition={competition} />
                        }
                        {
                            toggles.googleForm ?
                                <iframe
                                    src="https://docs.google.com/forms/d/e/1FAIpQLSfg39UZFO-NHJlKr3lg8kEza9IU1GjLcy7HYlnIv1INiodkMg/viewform?embedded=true"
                                    // src="https://docs.google.com/forms/d/e/1FAIpQLSezofo5hAF5xuBK2abxiGIjzJ1h_RLBknffywXX6IAkmxIOFw/viewform?embedded=true"
                                    width="640" height="1204" frameBorder="0" marginHeight={0}
                                    marginWidth={0}>Loading…</iframe> :
                                <EventRegistrationFlow competitionId={competitionId}/>
                        }


                        <br/>
                    </Grid>
                    <SideGrid container item xs={12} md={4} spacing={3}>
                        <Grid item xs={12} sm={6} md={12}>
                            <Results competition={competition}/>
                            <br/>
                            {competition.venue && (
                                <VenueCard
                                    {...competition.venue}
                                    withLink
                                    href={mapRoute(competition.venue.url)}
                                    imageURL={competition.venue.imageURL || fallbackImg}
                                    showMap={competition.program === "Program 1" || competition.program === "Program 2"}
                                />
                            )}
                        </Grid>

                        <Grid item xs={12} sm={6} md={12}>
                            <CompetitionForms agenda={competition.agenda} />
                            <br/>
                            {programItems && programItems.length > 0 && (
                                <>
                                    <Typography variant="h5" color={'secondary'}>
                                        {competition.program}
                                    </Typography>
                                    <ProgramTable programItems={programItems}/>
                                </>
                            )}
                        </Grid>
                    </SideGrid>
                </Grid>
            </>
        );
    }

    return <ErrorComponent title={'We were unable to show this event at this point in time.'}/>;
};

// @ts-ignore
export const Event = enhance(RegisterView);
