export interface LoadScriptProps {
  id: string
  setupScriptFunction: (scriptElement: HTMLScriptElement) => void
  documentSection?: 'body' | 'head'
}

export const setupFacebook = (loadScript: (props: LoadScriptProps) =>  void) => {
  const facebookSdkAppId = '325082032401514';

  // @ts-ignore
  if (facebookSdkAppId !== '' && facebookSdkAppId !== 'null') {
    loadScript({
      id: 'facebook_sdk',
      setupScriptFunction: (script: HTMLScriptElement) => {
        script.async = true
        script.defer = true
        script.crossOrigin = 'anonymous'
        script.src = `https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v9.0&appId=${facebookSdkAppId}&autoLogAppEvents=1`
      },
    })
  }
}
