import { RouteKeys } from '@constants';
import { compact } from 'lodash/fp';
import React from 'react';
import { NavBarFooter } from '@queensland-running/qr-components';
import { useConfig } from '@constants/config';
import { toRoutes } from '@utils/mapper';

const AppFooterView = () => {
  const {
    constants: { appTitle, currentYear },
  } = useConfig();

  const navRoutes = compact([
    RouteKeys.appBase,
    RouteKeys.about,
    RouteKeys.courseSignage,
    RouteKeys.events,
    RouteKeys.programs,
    RouteKeys.registration,
    RouteKeys.venues,
    RouteKeys.volunteer,
  ]);

  return <NavBarFooter appTitle={appTitle}
                       currentYear={currentYear}
                       navRoutes={toRoutes(navRoutes, currentYear)}
  />;
};

export const AppFooter = AppFooterView;
