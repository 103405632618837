import {useConfig} from "@constants/config";
import {useTranslation} from "react-i18next";
import {ProgramItem} from "@generated/schema";

type Program = {
    name: string,
    program: ProgramItem[]
}

const usePrograms = () => {
    const {t} = useTranslation('common')
    const {toggles} = useConfig();
    const programFilter = [
        !toggles.rockettRelays && "Rockett Relays",
        !toggles.allSchoolsChampionships && "All Schools Championships",
        !toggles.winterChampionships && "Winter Championships",
    ]
    // Data is driven from translation
    const programs = t<string, Program[]>('programs.program', {returnObjects: true})
        .filter(({name}) => !programFilter.includes(name))

    const getProgram = (program: string | undefined | null) => programs.find((p) => p.name === program)?.program

    return {programs, getProgram}
}

export {usePrograms}