import React from 'react';
import { PageTitle, UnstyledLink } from '@queensland-running/qr-components';
import { OpenInNew } from '@material-ui/icons';
import { Button, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { Section } from '@components/section/section';
import { Contact } from '@components/contacts';
import { PaymentInfoSection } from '@components/payment';
import { RouteLabels, Routes } from '@constants';

const RegistrationView = () => {
  return (
    <>
      <PageTitle title={RouteLabels.registration} />

      <Section verticalSpacing>
        <Typography>
          There are two ways of entering our events. They are <b>Prepaid Season Registration</b>, and <b>Casual Entry.</b>
        </Typography>
        <br />
        <Divider />
        <br />
        <Typography variant="h3" color="primary" id='prepaidRegistration'>
          1. Prepaid Season Registration
        </Typography>
        <Typography>
          This method of payment includes all events for the season.
        </Typography>
        <Typography>
          Runners do not have to complete weekly entry forms or present to the Registration Tent each week. We ask that you register online if you and your family are going to run, this assists in our record keeping.
        </Typography>
        <Typography>The cost is as follows:</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Age</TableCell>
              <TableCell>Fee</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Under 18</TableCell>
              <TableCell>$40.00</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Adult</TableCell>
              <TableCell>$50.00</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Family *</TableCell>
              <TableCell>$150.00</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <br />
        <Typography>
          <i>
            * A family includes a maximum of two adults (18+ years) and unlimited (own) children under 18 years of age.
            If your family includes children who are 18+ and are still students, then these children can be included in
            the family category on proof of student status (student ID card dated with the current year).
          </i>
        </Typography>
        <Typography>
          <i>
            <b>Please note</b> that for some families, payment of individual prepaid season registration fees may be a cheaper option. The
            family rate is intended for large families and/or those with older children.
          </i>
        </Typography>
        <Typography
          component="a"
          href="/assets/pdfs/forms/2024 Prepaid Season Registration Form.pdf"
          target="_blank"
          style={{ display: 'flex', alignItems: 'center' }}>
          <OpenInNew color="primary" style={{ marginRight: '0.5rem' }} />
          Prepaid Registration Form
        </Typography>
        <br />
        <Divider />
        <br />
        <Typography variant="h3" color="primary">
          2. Casual Entry
        </Typography>
        <Typography>There are no limits to the number of races that you may participate in throughout the afternoon. Come along and run on the day and pay.</Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Age</TableCell>
              <TableCell>Fee</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Under 18</TableCell>
              <TableCell>$8.00</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Adult</TableCell>
              <TableCell>$10.00</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Family *</TableCell>
              <TableCell>$25.00</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <br />
        <Typography>
          <i>
            * A family includes a maximum of two adults (18+ years) and unlimited (own) children under 18 years of age.
            If your family includes children who are 18+ and are still students, then these children can be included in
            the family category on proof of student status (student ID card dated with the current year).
          </i>
        </Typography>
        <Typography
          component="a"
          href="/assets/pdfs/forms/2024 Casual Entry Form.pdf"
          target="_blank"
          style={{ display: 'flex', alignItems: 'center' }}>
          <OpenInNew color="primary" style={{ marginRight: '0.5rem' }} />
          Casual Entry Form
        </Typography>
        <br />
        <hr />
        <Typography variant="h3" color="primary">
          Registering to Run
        </Typography>
        <Typography>There are three options to register for a run.</Typography>
        <Typography variant="h4">1. Online Registration (preferred)</Typography>
        <Typography>Visit the Calendar and click on the desired event you wish to register for.</Typography>
        <Typography>
          <UnstyledLink to={Routes.calendar.replace(':year', '2024')}>
            <Button color="primary" variant="contained" size="large" style={{ alignSelf: 'flex-end' }}>
              Calendar
            </Button>
          </UnstyledLink>
        </Typography>
        <br />
        <Typography variant="h4">2. Email Registration</Typography>
        <Typography>
          Email <Contact name="qr" type="emailAddress" /> with the following details by 9:30am Saturday morning -
          preferably by Friday night.
        </Typography>
        <Typography>
          <b>Subject:</b> Queensland Running - Weekly Entry
        </Typography>
        <Typography>
          <b>Name – School/Club/Independent – Age – Date of Birth – Gender</b>
        </Typography>
        <Typography>
          You will receive a short confirmation email usually by 9:45am Saturday advising of receipt of your entry.
        </Typography>
        <Typography>
          When you arrive, please advise the volunteers at the Registration Tent that you entered online and pay the
          appropriate fee.
        </Typography>
        <br />
        <Typography variant="h4">3. In Person Registration</Typography>
        <Typography>
          Fill out a weekly entry form at the Registration Tent or download the form from the website and give it to a
          volunteer along with the appropriate fee.
        </Typography>
        <br />
        <hr />
        <PaymentInfoSection cashOptionAvailable directDebitOptionAvailable directDebitReference="(your name)" />
        <Typography variant="h3" color="primary">
          Additional information
        </Typography>
        <Typography>
          All runners will receive an allocated number which they are to wear either on the front of their
          shirt/singlet or on a belt.
        </Typography>
        <Typography>
          Numbers are issued to runners to assist volunteers in recording accurate race results. Our numbers can be read
          easily from a distance speeding up recording and shortening the finish line queues.
        </Typography>
        <Typography>
          <b>New Runners</b>
        </Typography>
        <Typography>
          We recommend you email your first entry so a running number can be allocated before the event and it will be
          ready for collection prior to your first race. Please allow at least 30 minutes prior to your event’s start
          time if collecting your number.
        </Typography>
        <Typography>
          <b>Continuing Runners</b>
        </Typography>
        <Typography>
          Runners with already allocated numbers do not have to come to the Registration Tent prior to their first race.
          This eases the queue before the first race. Please pay your fee during the afternoon.
        </Typography>
        <Typography>
          If you require a replacement number due to wear and tear, please email us at:{' '}
          <Contact name="qr" type="emailAddress" /> with your name and number and we will have it ready for you at the
          next meet.
        </Typography>
        {/*<Typography>A list of running numbers is available on the website.</Typography>*/}
      </Section>
    </>
  );
};

export default RegistrationView;
