import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {UnstyledA} from '@queensland-running/qr-components';
import {compose, lifecycle} from 'recompose';
import {FacebookPage} from '@components/social/facebook-page';

const enhance = compose(
  lifecycle({
    componentDidMount() {
      // @ts-ignore
      if (window.FB) {
        // @ts-ignore
        window.FB.XFBML.parse();
      }
    },
  }),
);

const ComingSoonView = () => {
  document.title = `Queensland Running`;
  return (
    <>
      <img
        style={{
          height: '30rem',
          marginTop: '-1rem',
          width: '100%',
          objectFit: 'cover',
        }}
        src="/assets/images/banners/logo_825x465.jpg"
        alt="decorative queensland running banner with logo"
      />
      <br/>
      <br/>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <FacebookPage/>
        </Grid>
        <Grid item xs={12} sm={6} md={9}>
          <Typography variant="h1" style={{textAlign: 'center'}}>
            We're preparing for our new season
          </Typography>
          <br/>
          <Typography style={{textAlign: 'center'}}>
            Keep an eye on our{' '}
            <UnstyledA
              href={'https://www.facebook.com/queenslandrunning'}
              aria-label={'Queensland Running Facebook page'}>
              Facebook
            </UnstyledA>{' '}
            page for updates, and we look forward to seeing you in our upcoming season.
          </Typography>
        </Grid>
        <br/>
      </Grid>
    </>
  );
};

export const ComingSoon = enhance(ComingSoonView);
