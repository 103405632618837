import React, {useContext} from 'react';
import {compose, withProps} from 'recompose';
import {RouteKeys} from '@constants';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import UserContext from 'UserContext';
import {CognitoUser} from '@aws-amplify/auth';
import {NavBarHeader} from '@queensland-running/qr-components';
import {toRoutes} from '@utils/mapper';
import {Auth} from 'aws-amplify';

type AppNavViewProps = {
  current: string;
  active: number;
  user?: CognitoUser;
  isLoading?: boolean;
};

const enhance = compose<AppNavViewProps, {}>(
  withRouter,
  withProps(({ location: { pathname } }: RouteComponentProps) => {
    return {
      current: pathname,
    };
  }),
  React.memo,
);

const AppNavView = ({current}: AppNavViewProps) => {
  // @ts-ignore
  const { user } = useContext(UserContext);
  // @ts-ignore
  const isAuthenticated = !!(user && user.username);
  const currentYear = '2024';
  const routes = [RouteKeys.venues, RouteKeys.calendar, RouteKeys.events, RouteKeys.programs, RouteKeys.volunteer, RouteKeys.registration];
  // const profileRoutes = [RouteKeys.account, RouteKeys.runners];

  return (
    <NavBarHeader
      appTitle="Queensland Running"
      current={current}
      isAuthenticated={isAuthenticated}
      navRoutes={toRoutes(routes, currentYear)}
      authenticatedNavRoutes={[]}
      onLogout={() => {
        Auth.signOut();
      }}
    />
  );
};

export const AppNav = enhance(AppNavView);
