import React, {useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import { StepNavigator, StepProps } from '../event-registration-flow';
import { useRegistration } from '@constants/registration-context';
import {LoadingComponent} from "@queensland-running/qr-components";

export const SelectConfirmationStep  = ({ handleBack }: StepProps) => {
  const {registrationId} = useRegistration();

  useEffect(() => {
    if (!registrationId) {
      const timer = setTimeout(() => {
        handleBack!()
      }, 1500)
      return () => clearTimeout(timer)
    }
  }, [registrationId, handleBack])

  if (!registrationId) {
    return (
      <>
        <br/>
        <LoadingComponent message="Confirming registration..."/>
      </>
    );
  }

  return (
    <>
      <Typography variant="h5" color="primary">
        Confirmation
      </Typography>
      <br/>
      <StepNavigator />
    </>
  );
};
