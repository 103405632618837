import React, {Fragment} from 'react';
import {Grid, Hidden, Typography} from '@material-ui/core';
import styled from 'styled-components';
import {PageTitle} from '@queensland-running/qr-components';
import {useTranslation} from "react-i18next";

const Image = styled('img')`
  margin: auto;
  display: block;
  max-width: 100%;
  max-height: 100%;
  padding-bottom: 1rem;
`;

type TranslationContent = {
    imageSrc?: string,
    text?: string
    list?: string[]
}

const AboutView = () => {
    const {t} = useTranslation('common')
    const aboutContent = t<string, TranslationContent[]>('aboutUs.content', {returnObjects: true})

    return (
        <>
            <PageTitle title={t('app.routes.aboutUs')}/>
            <Grid container spacing={3}>
                <Grid item md={8} lg={6}>
                    {
                        aboutContent.map(({text, list}, position) =>
                            <Fragment key={position}>
                                {text && <Typography gutterBottom>{text}</Typography>}
                                {list && (
                                    <ul>
                                        {
                                            list.map((listIndex, position) => (
                                                <li key={position}>
                                                    <Typography>{listIndex}</Typography>
                                                </li>
                                            ))
                                        }
                                    </ul>)
                                }
                            </Fragment>)
                    }
                </Grid>
                <Grid item md={4} lg={6}>
                    <Image src="/assets/images/banners/logo_825x465.jpg"
                           alt="decorative queensland running banner with logo"/>
                    <Hidden smDown>
                        <Image src="/assets/images/general/brigade.jpg"
                               alt="decorative queensland running banner with logo"/>
                    </Hidden>
                </Grid>
            </Grid>
        </>
    );
};

export const About = AboutView;
