// @ts-ignore

import React, { Component } from 'react';
import { Auth, Hub } from 'aws-amplify';
import * as Sentry from '@sentry/react';
import UserContext from './UserContext';
import { App } from './App';

class AuthApp extends Component {
  state = {
    currentUser: {},
    isLoaded: false,
  };
  componentDidMount() {
    // @ts-ignore
    this.updateCurrentUser();
    Hub.listen('auth', this);
  }
  // @ts-ignore
  onHubCapsule(capsule) {
    const { channel, payload } = capsule;
    if (channel === 'auth' && payload.event !== 'signIn') {
      // @ts-ignore
      this.updateCurrentUser();
    }
  }
  // @ts-ignore
  updateCurrentUser = async (user) => {
    if (user) {
      this.setState({ currentUser: user });
      Sentry.setUser({ id: user.username, email: user.attributes.email })
      return;
    }
    try {
      const user = await Auth.currentAuthenticatedUser();
      this.setState({ currentUser: user, isLoaded: true });
      Sentry.setUser({ id: user.username, email: user.attributes.email })
    } catch (err) {
      this.setState({ currentUser: null, isLoaded: true });
      Sentry.setUser(null)
    }
  };
  render() {
    return (
      <UserContext.Provider
        value={{
          // @ts-ignore
          user: this.state.currentUser,
          updateCurrentUser: this.updateCurrentUser,
          isLoaded: this.state.isLoaded,
        }}>
        <App />
      </UserContext.Provider>
    );
  }
}

export default AuthApp;
