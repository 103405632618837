/* eslint-disable */
import * as Types from '../../../@generated/schema.d';

import { gql } from 'graphql.macro';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHoc from '@apollo/client/react/hoc';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FetchAllParksQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FetchAllParksQuery = { readonly __typename?: 'Query' } & {
  readonly venues?: Types.Maybe<
    ReadonlyArray<
      Types.Maybe<
        { readonly __typename?: 'Venue' } & Pick<
          Types.Venue,
          'id' | 'displayName' | 'name' | 'suburb' | 'imageUrl' | 'street' | 'url' | 'archived'
        >
      >
    >
  >;
};

export type FetchParkQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type FetchParkQuery = { readonly __typename?: 'Query' } & {
  readonly venue?: Types.Maybe<
    { readonly __typename?: 'Venue' } & Pick<
      Types.Venue,
      | 'id'
      | 'name'
      | 'displayName'
      | 'suburb'
      | 'imageUrl'
      | 'street'
      | 'url'
      | 'googleMapsSrc'
      | 'details'
      | 'archived'
    >
  >;
};

export const FetchAllParksDocument = gql`
  query fetchAllParks {
    venues {
      id
      displayName
      name
      suburb
      imageUrl
      street
      url
      archived
    }
  }
`;
export type FetchAllParksComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<FetchAllParksQuery, FetchAllParksQueryVariables>,
  'query'
>;

export const FetchAllParksComponent = (props: FetchAllParksComponentProps) => (
  <ApolloReactComponents.Query<FetchAllParksQuery, FetchAllParksQueryVariables>
    query={FetchAllParksDocument}
    {...props}
  />
);

export type FetchAllParksProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<FetchAllParksQuery, FetchAllParksQueryVariables>;
} &
  TChildProps;
export function withFetchAllParks<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FetchAllParksQuery,
    FetchAllParksQueryVariables,
    FetchAllParksProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FetchAllParksQuery,
    FetchAllParksQueryVariables,
    FetchAllParksProps<TChildProps, TDataName>
  >(FetchAllParksDocument, {
    alias: 'fetchAllParks',
    ...operationOptions,
  });
}

/**
 * __useFetchAllParksQuery__
 *
 * To run a query within a React component, call `useFetchAllParksQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllParksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllParksQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAllParksQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchAllParksQuery, FetchAllParksQueryVariables>,
) {
  return Apollo.useQuery<FetchAllParksQuery, FetchAllParksQueryVariables>(FetchAllParksDocument, baseOptions);
}
export function useFetchAllParksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchAllParksQuery, FetchAllParksQueryVariables>,
) {
  return Apollo.useLazyQuery<FetchAllParksQuery, FetchAllParksQueryVariables>(FetchAllParksDocument, baseOptions);
}
export type FetchAllParksQueryHookResult = ReturnType<typeof useFetchAllParksQuery>;
export type FetchAllParksLazyQueryHookResult = ReturnType<typeof useFetchAllParksLazyQuery>;
export type FetchAllParksQueryResult = Apollo.QueryResult<FetchAllParksQuery, FetchAllParksQueryVariables>;
export const FetchParkDocument = gql`
  query fetchPark($id: String!) {
    venue(id: $id) {
      id
      name
      displayName
      suburb
      imageUrl
      street
      url
      googleMapsSrc
      details
      archived
    }
  }
`;
export type FetchParkComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<FetchParkQuery, FetchParkQueryVariables>,
  'query'
> &
  ({ variables: FetchParkQueryVariables; skip?: boolean } | { skip: boolean });

export const FetchParkComponent = (props: FetchParkComponentProps) => (
  <ApolloReactComponents.Query<FetchParkQuery, FetchParkQueryVariables> query={FetchParkDocument} {...props} />
);

export type FetchParkProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<FetchParkQuery, FetchParkQueryVariables>;
} &
  TChildProps;
export function withFetchPark<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FetchParkQuery,
    FetchParkQueryVariables,
    FetchParkProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FetchParkQuery,
    FetchParkQueryVariables,
    FetchParkProps<TChildProps, TDataName>
  >(FetchParkDocument, {
    alias: 'fetchPark',
    ...operationOptions,
  });
}

/**
 * __useFetchParkQuery__
 *
 * To run a query within a React component, call `useFetchParkQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchParkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchParkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchParkQuery(baseOptions: Apollo.QueryHookOptions<FetchParkQuery, FetchParkQueryVariables>) {
  return Apollo.useQuery<FetchParkQuery, FetchParkQueryVariables>(FetchParkDocument, baseOptions);
}
export function useFetchParkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchParkQuery, FetchParkQueryVariables>,
) {
  return Apollo.useLazyQuery<FetchParkQuery, FetchParkQueryVariables>(FetchParkDocument, baseOptions);
}
export type FetchParkQueryHookResult = ReturnType<typeof useFetchParkQuery>;
export type FetchParkLazyQueryHookResult = ReturnType<typeof useFetchParkLazyQuery>;
export type FetchParkQueryResult = Apollo.QueryResult<FetchParkQuery, FetchParkQueryVariables>;
