import React from 'react';
import {Redirect, RouteComponentProps, withRouter} from 'react-router-dom';
import {compose, withProps} from 'recompose';
import {Grid, Typography} from '@material-ui/core';
import {FetchParkDocument, FetchParkQuery, FetchParkQueryVariables} from './queries.generated';
import {RouteKeys, Routes} from '@constants';
import {useQuery} from '@apollo/client';
import {ErrorComponent, LoadingComponent, PageTitle, VenueCard} from '@queensland-running/qr-components';
import {toRoutes} from '@utils/mapper';
import {Venue} from "@generated/schema";

type VenueProps = {};
type VenueViewProps = { id: string };
type ParamProps = { name?: string };

const enhance = compose<VenueViewProps, VenueProps>(
  withRouter,
  withProps(({match: {params}}: RouteComponentProps) => {
    const {name: id} = params as ParamProps;
    if (!id) return <Redirect to={Routes.venues}/>;
    return {id};
  }),
  React.memo,
);

const VenueView = ({id}: VenueViewProps) => {
  const {data, loading, error} = useQuery<FetchParkQuery, FetchParkQueryVariables>(FetchParkDocument, {
    variables: {id},
  });

  if (loading) return <LoadingComponent/>;
  if (error) return <ErrorComponent/>;
  if (!data?.venue) return <Redirect to={Routes.venues}/>;

  return (
    <>
      <PageTitle title={data.venue.name} breadcrumbs={toRoutes([RouteKeys.venues])}/>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <VenueCard {...(data.venue as Venue)} />
        </Grid>
        <Grid item xs={12} md={8}>
          {data.venue.details.length > 0 && (
            <Typography variant="h2" color="primary" gutterBottom>
              The Course
            </Typography>
          )}
          {data.venue.details.map((detail: string, index: number) => (
            <Typography key={`${detail.substr(0, 10)}-${index}`} variant="body1" gutterBottom>
              {detail}
            </Typography>
          ))}
        </Grid>
        <Grid item xs={12} md={12}>
          {data.venue.googleMapsSrc && <iframe title="google-maps" src={data.venue.googleMapsSrc} width="100%" height="520" />}
        </Grid>
      </Grid>
    </>
  );
};

export const VenuePage = enhance(VenueView);
