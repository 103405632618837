import React, { Fragment, useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import {
  Blockquote,
  FieldCustomValue,
  LoadingComponent,
  RegisterAthletesTable,
  TypographyWithIcon,
  UnstyledLink, WeeklyRunnersInput,
} from '@queensland-running/qr-components';
import {RegisterRunner, RegisterWeeklyCompetitionRunner, Runner, WeeklyCompetitionRunnerDetails} from '@generated/schema';
import { Routes } from '@constants';
import { Warning } from '@material-ui/icons';
import {StepNavigator, StepProps} from '../../event-registration-flow';
import { useRegisterRunnersForWeeklyCompetitionMutation } from './mutations.generated';
import { useCompetitionDetailsQuery } from '../../queries.generated';
import { useRegistration } from '@constants/registration-context';

interface RegisterForEventProps {
  competitionId: string
}
type RunnerWithId = RegisterWeeklyCompetitionRunner & { id: string };
const useRegisterForEvent = ({ competitionId }: RegisterForEventProps) => {
  const { data: eventData, loading } = useCompetitionDetailsQuery({
    variables: {
      id: competitionId,
    }
  });

  return {
    runners: (eventData?.runners?.data! as Runner[]) ?? [],
    competition: eventData?.competition,
    userAccount: eventData?.userAccount,
    loading,
  };
};
export const WeeklyRegistration = ({ handleNext, handleSkip, handleBack, competitionId }: StepProps) => {
  const { registrationId, locationId, setOrderId } = useRegistration();
  const { competition, runners } = useRegisterForEvent({ competitionId });
  const [selectedRunners, setSelectedRunners] = useState(new Set<string>());
  const [temporaryRunners, setTemporaryRunners] = useState<RunnerWithId[]>([]);
  const [registerRunnersForEvent, { loading }] = useRegisterRunnersForWeeklyCompetitionMutation({
    onCompleted(data) {
      // @ts-ignore
      setOrderId(data.competition?.registerRunnersForWeeklyCompetition?.orderId);
      handleNext!();
    },
  });

  useEffect(() => {
    if (competition) {
      switch (competition.__typename) {
        case 'WeeklyCompetition':
          if (competition.weeklyCompetitionRegistration?.runnerDetails) {
            const runnersForAccount = runners?.map(({ id }) => id);
            const runnerIds = (competition.weeklyCompetitionRegistration.runnerDetails as WeeklyCompetitionRunnerDetails[]).map(({ id }) => id);
            // .filter((id) => runnersForAccount?.includes(id))
            // TODO
            console.log({
              possible: runnersForAccount,
              selectedRunners: competition.weeklyCompetitionRegistration.runnerDetails,
            });
            // @ts-ignore
            setSelectedRunners(new Set(runnerIds));
          }
          break;
        default:
          break;
      }
    }
  }, [competition, runners]);

  if (!registrationId) {
    return <div>Weekly registration wrong</div>;
  }

  const handleSubmit = () => {
    const chosenRunners = Array.from(selectedRunners);
    registerRunnersForEvent({
      variables: {
        input: {
          registrationId,
          locationId,
          runners: runners
            .filter(({ id }) => chosenRunners.includes(id))
            .map(
              ({ id, bibNumber, club, firstName, lastName, membership, ...runner }: Runner): RegisterRunner => ({
                id,
                bibNumber,
                club,
                firstName,
                lastName,
                membership,
                gender: runner.gender ? runner.gender : null,
                dateOfBirth: runner.dateOfBirth, //moment(runner.dateOfBirth!).format('YYYY-MM-DD')
              }),
            ),
        },
      },
    });
  };

  if (loading) {
    return (
      <>
        <br />
        <LoadingComponent message="Confirming runner details..." />
      </>
    );
  }

  const handleClick = (event: any, name: string) => {
    const newRunners = new Set(selectedRunners.values());

    if (newRunners.has(name)) {
      newRunners.delete(name);
    } else {
      newRunners.add(name);
    }

    setSelectedRunners(newRunners);
  };

  const handleClickAll = () => {
    const allRunners = new Set(runners?.map(({ id }) => id));
    setSelectedRunners(allRunners ?? selectedRunners);
  };

  return (
    <>
      <Typography variant="h5" color="primary">
        Who is running?
      </Typography>
      <FieldCustomValue
        label="Runners"
        name="runners"
        CustomValue={() =>
          runners && runners.length > 0 ? (
            <Fragment>
              <RegisterAthletesTable
                athletes={(runners as Runner[]) || []}
                disabled={false}
                // disabled={formik.isSubmitting || submitted || isRegistered}
                selected={Array.from(selectedRunners)}
                handleSelectAllClick={handleClickAll}
                handleClick={handleClick}
              />
              <br />
              <Typography>
                Can't see the runner you are looking for? Register{' '}
                <UnstyledLink style={{ display: 'contents' }} to={Routes.runners}>
                  runners
                </UnstyledLink>
                .
              </Typography>
            </Fragment>
          ) : (
            <Blockquote color={'neutral'}>
              <TypographyWithIcon>
                <Warning fontSize="small" />
                &nbsp;Looks like you haven't registered any runners. Please register by clicking on this{' '}
                <UnstyledLink style={{ display: 'contents' }} to={Routes.runners}>
                  link
                </UnstyledLink>
                .
              </TypographyWithIcon>
            </Blockquote>
          )
        }
      />
      <br/>
      <WeeklyRunnersInput
        label="Guest Runners"
        name="guest-runners"
        errors={''}
        value={temporaryRunners}
        onChange={(runners) => setTemporaryRunners(runners)}
        eventDate={competition?.day}
      />
      <br/>
      <StepNavigator
        handleBack={handleBack}
        backText={'Back'}
        handleNext={handleNext}
        nextText={'Next'}
        handleSkip={handleSkip}
        skipText={'Complete on the day'}
      />
    </>
  );
};
