import * as env from '@constants/environment';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { Auth } from 'aws-amplify';
import { setContext } from '@apollo/client/link/context';
import * as Sentry from '@sentry/react';
import { stage } from '@constants/environment';

const httpLink = createHttpLink({
  uri: env.graphqlApiUri,
});

const authLink = setContext(async (_, { headers }) => {
  let token;
  try {
    //@ts-ignore
    token = await Auth.currentSession().then((session) => session.idToken.jwtToken);
  } catch (e) {
    if (stage !== 'production') {
      console.error('Error', e);
    }
  }

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path }) => {
      const err = new Error(`Message: ${message}, Path: ${path}`);
      err.name = 'GraphQL error';
      Sentry.captureException(err);
      if (stage !== 'production') {
        console.error(err);
      }
    });
  }

  if (networkError) {
    Sentry.captureException(networkError);
    if (stage !== 'production') {
      console.error(networkError);
    }
  }
});

export const createClient = () =>
  new ApolloClient({
    link: errorLink.concat(authLink).concat(httpLink),
    connectToDevTools: env.stage !== 'production',
    cache: new InMemoryCache(),
  });
