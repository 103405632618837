import {ErrorBoundary, ErrorBoundaryProps} from '@components/error-boundary';
import React from 'react';
import {nest} from 'recompose';

const errorBoundaryHoc = <P extends {}>(
  errorBoundaryProps: ErrorBoundaryProps
) => (component: React.ComponentType<P>) =>
  nest(
    (props: P) => <ErrorBoundary {...errorBoundaryProps} {...props} />,
    component
  );

const isComponent = <P extends {}>(
  value: ErrorBoundaryProps | React.ComponentType<P>
): value is React.ComponentType<P> =>
  value instanceof React.Component || typeof value === 'function';

export const withErrorBoundary = <P extends {}>(
  componentOrProps: ErrorBoundaryProps | React.ComponentType<P>
) =>
  isComponent(componentOrProps)
    ? errorBoundaryHoc<P>({})(componentOrProps)
    : errorBoundaryHoc<P>(componentOrProps);
