import React from 'react';
import { FormHelperText, Grid, MenuItem, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { date, number, object, string } from 'yup';
import { useCreateRunnerMutation } from './mutations.generated';
import moment from 'moment';
import { Runner } from '@generated/schema';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Modal } from '@queensland-running/qr-components';

type UpdateAdministratorProps = {
  open: boolean;
  onClose: () => void;
  setData: (data: Runner) => void;
};
type CreateRunnerModalViewProps = UpdateAdministratorProps;

const CreateRunnerModalView = ({ onClose, open, setData }: CreateRunnerModalViewProps) => {
  const [createRunner] = useCreateRunnerMutation({
    onCompleted: (data) => {
      const runnerData = data && data.runners && data.runners.createRunner;
      // @ts-ignore
      runnerData && setData(runnerData);
      onClose();
      resetForm();
    },
  });
  const {
    errors,
    values,
    touched,
    handleSubmit,
    handleChange,
    setFieldValue,
    handleBlur,
    isSubmitting,
    resetForm,
  } = useFormik({
    initialValues: {
      bibNumber: '',
      club: '',
      firstName: '',
      lastName: '',
      gender: '',
      dateOfBirth: null,
    },
    validationSchema: object().shape({
      firstName: string().required('A first name is required'),
      lastName: string().required('A last name is required'),
      bibNumber: number().notRequired(),
      club: string().notRequired(),
      dateOfBirth: date()
        .transform((_, rawValue) => moment(rawValue, ['yyyy-mm-dd']).toDate())
        .nullable()
        .min(moment(new Date(1900, 0, 0)).toDate(), 'Date must be later than 01/01/1900')
        .max(moment(new Date()).toDate(), 'Date must be in the past.')
        .typeError('Please enter a valid date'),
    }),
    onSubmit: (values) => {
      createRunner({
        variables: {
          input: {
            bibNumber: values.bibNumber ? values.bibNumber.toString() : null,
            firstName: values.firstName === '' ? null : values.firstName,
            lastName: values.lastName === '' ? null : values.lastName,
            club: values.club === '' ? null : values.club,
            dateOfBirth: moment(values.dateOfBirth!).format('YYYY-MM-DD'),
            gender: values.gender === '' ? null : values.gender,
          },
        },
      });
    },
  });
  return (
    <Modal
      onClose={() => {
        onClose();
        resetForm();
      }}
      open={open}
      title={'Add Runner'}
      actionDisabled={isSubmitting}
      primaryAction={handleSubmit}
      primaryActionText="Add runner"
      primaryActionColor="primary"
      secondaryAction={() => {
        onClose();
        resetForm();
      }}
      secondaryActionText="Close">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            error={!!(errors.firstName && touched.firstName)}
            label="First Name"
            name="firstName"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.firstName && touched.firstName && errors.firstName}
            disabled={isSubmitting}
            margin="normal"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            error={!!(errors.lastName && touched.lastName)}
            required
            label="Last Name"
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.lastName && touched.lastName && errors.lastName}
            disabled={isSubmitting}
            margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <KeyboardDatePicker
            required
            error={!!(errors.dateOfBirth && touched.dateOfBirth)}
            label="Date of Birth"
            name="dateOfBirth"
            value={values.dateOfBirth}
            onChange={(value) => {
              setFieldValue('dateOfBirth', value);
            }}
            onBlur={handleBlur}
            helperText={errors.dateOfBirth && touched.dateOfBirth && errors.dateOfBirth}
            margin="normal"
            // disabled={submitted}
            fullWidth
            maxDate={new Date()}
            format="DD/MM/YYYY"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            select
            id="gender"
            label="Gender"
            error={!!(errors.gender && touched.gender)}
            name="gender"
            value={values.gender}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            style={{ marginTop: '1rem' }}>
            <MenuItem value="">
              <em>--</em>
            </MenuItem>
            <MenuItem value="Prefer not to say">Prefer not to say</MenuItem>
            <MenuItem value={'Male'}>Male</MenuItem>
            <MenuItem value={'Female'}>Female</MenuItem>
            <MenuItem value={'Other'}>Other</MenuItem>
          </TextField>
          {errors.gender && touched.gender && <FormHelperText>{errors.gender}</FormHelperText>}
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={!!(errors.club && touched.club)}
            label="Club/School/Independent"
            name="club"
            value={values.club}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.club && touched.club && errors.club}
            disabled={isSubmitting}
            margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            error={!!(errors.bibNumber && touched.bibNumber)}
            label="Bib Number"
            name="bibNumber"
            type="number"
            value={values.bibNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.bibNumber && touched.bibNumber ? errors.bibNumber : 'Leave blank if unsure or new'}
            disabled={isSubmitting}
            margin="normal"
            fullWidth
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export const CreateRunnerModal = React.memo(CreateRunnerModalView);
