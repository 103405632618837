import React, {useContext} from 'react';
import Typography from '@material-ui/core/Typography';
import {Button, LoadingComponent, UnstyledLink,} from '@queensland-running/qr-components';
import {StepProps} from '../event-registration-flow';
import {CompetitionType, MultiRegistration} from '../multi-registration-flow';
import {Section} from '@components/section/section';
import {Grid} from '@material-ui/core';
import UserContext from '../../../../UserContext';
import {SingleRegistration} from '../single-registration-flow';
import {RegisterContactDetailsForCompetitionInputPartial,} from '@generated/schema';
import {useCompetitionDetailsQuery} from '../queries.generated';
import {useRegistration} from '@constants/registration-context';
import {useRegisterContactDetailsForCompetitionMutation} from '../mutations.generated';
import {AdditionalRegistrationDetails} from "./contact-details/additional-details";
import {useTranslation} from "react-i18next";

interface RegisterForEventProps {
    competitionId: string;
}

const useRegisterForEvent = ({competitionId}: RegisterForEventProps) => {
    const {data: eventData, loading} = useCompetitionDetailsQuery({
        variables: {
            id: competitionId,
        },
        errorPolicy: 'all'
    });

    return {competition: eventData?.competition, userAccount: eventData?.userAccount, loading};
};
export const ContactDetailsStep = ({handleNext, competitionId}: StepProps) => {
    const {setRegistrationId, setCompetitionRegistration__typename} = useRegistration();
    const { t } = useTranslation('common')
    const {user} = useContext(UserContext);
    const {competition, userAccount, loading} = useRegisterForEvent({competitionId});
    const [registerForEvent] = useRegisterContactDetailsForCompetitionMutation({
        onCompleted: (data) => {
            setCompetitionRegistration__typename(data.competition?.registerContactDetailsForCompetition.__typename);
            setRegistrationId(data?.competition?.registerContactDetailsForCompetition?.id);
            handleNext!();
        },
    });

    if (loading) {
        return (
            <>
                <br/>
                <LoadingComponent message="Loading contact details..."/>
            </>
        );
    }
    if (!competition) {
        return (
            <>
                <br/>
                <>No competition selected</>
            </>
        );
    }

    const registerForEventFunction = (input: RegisterContactDetailsForCompetitionInputPartial) => {
        registerForEvent({
            variables: {
                input: {
                    ...input,
                    competitionId: competition.id,
                },
            },
        });
    };

    return (
        <>
            {user ? (
                <MultiRegistration onSubmit={registerForEventFunction} userAccount={userAccount}
                                   competition={competition as CompetitionType}
                                   details={AdditionalRegistrationDetails}/>
            ) : (
                <>
                    <br/>
                    <Section verticalSpacing>
                        <Typography>If you are registering to this event with multiple individuals, please sign
                            in.</Typography>
                    </Section>
                    <br/>
                    <Grid container spacing={3} direction="column" alignItems="center">
                        <UnstyledLink to={'/auth'}>
                            <Button variant="contained" color="secondary" title={t('account.buttons.signIn')}/>
                        </UnstyledLink>
                    </Grid>
                    <br/>
                    <SingleRegistration onSubmit={registerForEventFunction}
                                        competition={competition as CompetitionType}
                                        details={AdditionalRegistrationDetails}/>
                </>
            )}
        </>
    );
};
