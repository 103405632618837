import React, { createContext, ReactNode, useContext, useState } from 'react';

const EventContext = createContext<EventConfig>({
  eventId: '',
  setEventId: () => {},
});

export interface EventConfig {
  eventId?: string;
  setEventId: (eventId: string | undefined) => void;
}

export const useEvent = () => {
  const config = useContext(EventContext);

  if (!config) {
    throw new Error('Configuration should already be loaded');
  }

  return config;
};

const EventProvider = ({ children }: { children: ReactNode }) => {
  const [eventId, setEventId] = useState<string | undefined>(undefined);
  return <EventContext.Provider value={{ eventId, setEventId }}>{children}</EventContext.Provider>;
};

export default EventProvider;
