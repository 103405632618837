import React from 'react';
import {Table, TableBody, TableCell, TableRow, Typography} from '@material-ui/core';
import {countBy, flow} from 'lodash/fp';
import {Section} from '@components/section/section';

var numberWords = (int: number) => ['No', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'][int];

const PaymentView = (options: {
  cashOptionAvailable?: boolean;
  directDebitOptionAvailable?: boolean;
  eftposOptionAvailable?: boolean;
  directDebitReference?: string;
  ref?: React.RefObject<HTMLDivElement>;
}) => (
  <div ref={options.ref}>
    <Section verticalSpacing>
      <Typography variant="h3" color="primary">
        Payment
      </Typography>
      <Typography>
        Payment must be made at time of registration.{' '}
        {flow(
          countBy((option?: boolean) => option === true),
          ({true: trueCount}) => trueCount,
          numberWords,
        )(Object.values(options))}{' '}
        methods are available:
      </Typography>
      {options.cashOptionAvailable && (
        <>
          <Typography variant="h4">Cash</Typography>
          <Typography>At the registration tent when lodging your form.</Typography>
        </>
      )}
      {options.directDebitOptionAvailable && (
        <>
          <Typography variant="h4">Direct Debit</Typography>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography>
                    <b>BSB:</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>814-282</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>
                    <b>Account Number:</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>30217960</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>
                    <b>Reference:</b>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {options.directDebitReference
                      ? options.directDebitReference
                      : 'Family name and event numbers (if space permits)'}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <br/>
        </>
      )}
    </Section>
  </div>
);

export const PaymentInfoSection = PaymentView;
