import React from 'react';
import { compose } from 'recompose';
// import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
// import { Contact } from '@components';
import { ProgramItem } from '@generated/schema';
// import { Blockquote, ProgramTable } from '@queensland-running/qr-components';
// import { PaymentInfoSection } from '@components/payment';
// import { Section } from '@components/section/section';

// const entryFormUrl = '/assets/pdfs/forms/2019 WC Entry Form.pdf';

export const allSchoolsChampionships: ProgramItem[] = [
  {
    __typename: 'ProgramItem',
    event: '1',
    time: '10.30am',
    race: '500m',
    ageGroup: ['5 Years Girls'],
    yearBorn: ['2014'],
  },
  {
    __typename: 'ProgramItem',
    event: '2',
    time: '10.40am',
    race: '500m',
    ageGroup: ['5 Years Boys'],
    yearBorn: ['2014'],
  },
  {
    __typename: 'ProgramItem',
    event: '3',
    time: '10.50am',
    race: '800m',
    ageGroup: ['6 Years Girls'],
    yearBorn: ['2013'],
  },
  {
    __typename: 'ProgramItem',
    event: '4',
    time: '11.00am',
    race: '800m',
    ageGroup: ['6 Years Boys'],
    yearBorn: ['2013'],
  },
  {
    __typename: 'ProgramItem',
    event: '5',
    time: '11.10am',
    race: '800m',
    ageGroup: ['7 Years Girls'],
    yearBorn: ['2012'],
  },
  {
    __typename: 'ProgramItem',
    event: '6',
    time: '11.20am',
    race: '800m',
    ageGroup: ['7 Years Boys'],
    yearBorn: ['2012'],
  },
  {
    __typename: 'ProgramItem',
    event: '7',
    time: '11.35am',
    race: '1000m',
    ageGroup: ['8 Years Girls'],
    yearBorn: ['2011'],
  },
  {
    __typename: 'ProgramItem',
    event: '8',
    time: '11.45am',
    race: '1000m',
    ageGroup: ['8 Years Boys'],
    yearBorn: ['2011'],
  },
  {
    __typename: 'ProgramItem',
    event: '9',
    time: '11.55pm',
    race: '1500m',
    ageGroup: ['9 Years Girls'],
    yearBorn: ['2010'],
  },
  {
    __typename: 'ProgramItem',
    event: '10',
    time: '12.10pm',
    race: '1500m',
    ageGroup: ['9 Years Boys'],
    yearBorn: ['2010'],
  },
  {
    __typename: 'ProgramItem',
    event: '11',
    time: '12.20pm',
    race: '2000m',
    ageGroup: ['10 Years Girls'],
    yearBorn: ['2009'],
  },
  {
    __typename: 'ProgramItem',
    event: '12',
    time: '12.35pm',
    race: '2000m',
    ageGroup: ['10 Years Boys'],
    yearBorn: ['2009'],
  },
  {
    __typename: 'ProgramItem',
    event: '13',
    time: '12.50pm',
    race: '3000m',
    ageGroup: ['11 Years Girls'],
    yearBorn: ['2008'],
  },
  {
    __typename: 'ProgramItem',
    event: '14',
    time: '1.05pm',
    race: '3000m',
    ageGroup: ['11 Years Boys'],
    yearBorn: ['2008'],
  },
  {
    __typename: 'ProgramItem',
    event: '15',
    time: '1.25pm',
    race: '3000m',
    ageGroup: ['12 Years Girls'],
    yearBorn: ['2007'],
  },
  {
    __typename: 'ProgramItem',
    event: '16',
    time: '1.40pm',
    race: '3000m',
    ageGroup: ['12 Years Boys'],
    yearBorn: ['2007'],
  },
  {
    __typename: 'ProgramItem',
    event: '17',
    time: '1.55pm',
    race: '3000m',
    ageGroup: ['13 Years Girls'],
    yearBorn: ['2006'],
  },
  {
    __typename: 'ProgramItem',
    event: '18',
    time: '2.10pm',
    race: '3000m',
    ageGroup: ['13 Years Boys'],
    yearBorn: ['2006'],
  },
  {
    __typename: 'ProgramItem',
    event: '19-20',
    time: '2.25pm',
    race: '6000m',
    ageGroup: ['16 & 17 Years Boys'],
    yearBorn: ['2002', '2003'],
  },
  {
    __typename: 'ProgramItem',
    event: '21-22',
    time: '2.45pm',
    race: '6000m',
    ageGroup: ['18 & 19 Years Girls'],
    yearBorn: ['2000', '2001'],
  },
  {
    __typename: 'ProgramItem',
    event: '23-24',
    time: '2.45pm',
    race: '8000m',
    ageGroup: ['18 & 19 Years Boys'],
    yearBorn: ['2000', '2001'],
  },
  {
    __typename: 'ProgramItem',
    event: '25-26',
    time: '3.00pm',
    race: '4000m',
    ageGroup: ['14 & 15 Years Girls'],
    yearBorn: ['2004', '2005'],
  },
  {
    __typename: 'ProgramItem',
    event: '27-28',
    time: '3.15pm',
    race: '4000m',
    ageGroup: ['14 & 15 Years Boys'],
    yearBorn: ['2004', '2005'],
  },
  {
    __typename: 'ProgramItem',
    event: '29-30',
    time: '3.15pm',
    race: '4000m',
    ageGroup: ['16 & 17 Years Girls'],
    yearBorn: ['2002', '2003'],
  },
  {
    __typename: 'ProgramItem',
    event: '31*',
    time: '3.40pm',
    race: '4000m',
    ageGroup: ['Queensland Middle Distance'],
    yearBorn: ['Open and Masters'],
  },
];

const enhance = compose(React.memo);

// const AllSchoolsChampionshipView = () => (
//   <>
//     <Typography variant="h5" color={'secondary'}>
//       Program
//     </Typography>
//     <ProgramTable programItems={allSchoolsChampionships} />
//     <br />
//     <Typography variant="subtitle2">
//       <b>* Medals will be awarded for Open and Masters categories. Entry fee: $10.00 per athlete</b>
//     </Typography>
//     <br />
//     <br />
//     <Section verticalSpacing>
//       <Typography>
//         <b>ATHLETES WITH DISABILITIES (AWD)</b> - An invitation is extended to athletes with disabilities to compete in
//         the following age groups – AWD 10-12 years (2000 metres), 13-15 years and 16-19 years (3000 metres).
//       </Typography>
//       <Typography>Medals will be awarded for Athletes with Disabilities in each age group.</Typography>
//       <Typography>
//         <b>Where events have a combined start time, medals are awarded for each individual age event.</b>
//       </Typography>
//       <Typography>
//         <b>
//           NOTE: Event timetable subject to change based on number of entries. Please check website prior to Championship
//           to confirm start times.
//         </b>
//       </Typography>
//     </Section>
//
//     <Section verticalSpacing>
//       <Typography variant="h3" color="primary">
//         Entry Fees
//       </Typography>
//       <Typography>
//         Entry fee must be paid in person on or before Championship day for entry to be confirmed and accepted.
//       </Typography>
//       <Typography>Age is taken as age on the day of the championship.</Typography>
//
//       <Typography variant="h4">Registered Athletes</Typography>
//       <Blockquote color="primary">
//         Athletes who have paid the $12 season registration fee or prepayment option at the beginning of the season)
//       </Blockquote>
//       <Table size="small">
//         <TableHead>
//           <TableRow>
//             <TableCell>Age</TableCell>
//             <TableCell>Season Registered Athletes</TableCell>
//             <TableCell>Regular Casual Runners</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           <TableRow>
//             <TableCell>0-10 years</TableCell>
//             <TableCell>$2.00</TableCell>
//             <TableCell>$3.00</TableCell>
//           </TableRow>
//           <TableRow>
//             <TableCell>11-16 years</TableCell>
//             <TableCell>$3.00</TableCell>
//             <TableCell>$4.00</TableCell>
//           </TableRow>
//           <TableRow>
//             <TableCell>17+ years</TableCell>
//             <TableCell>$4.00</TableCell>
//             <TableCell>$5.00</TableCell>
//           </TableRow>
//         </TableBody>
//       </Table>
//       <br />
//       <Typography>
//         <b>Athletes without a Queensland Running race number: $7.00 (all ages)</b>
//       </Typography>
//     </Section>
//     <PaymentInfoSection cashOptionAvailable directDebitOptionAvailable />
//     <Section verticalSpacing>
//       <Typography variant="h3" color="primary">
//         How to enter
//       </Typography>
//       <Typography>
//         <b>Note for school teams:</b> School staff are invited to email: Anne Scott for a copy of the school’s entry
//         spreadsheet for bulk entries of students from the one school.
//       </Typography>
//       <Typography>
//         <b>
//           NOTE: Event entry age is taken as age at 31 December 2019. All competitors must compete in their own age
//           group.
//         </b>
//       </Typography>
//       <Typography>
//         Entries received by fax, email or online will be regarded as exempt from the late entry fee provided the
//         appropriate entry fees are received no later than Wednesday 15 May 2019. See below for details of late entries.
//       </Typography>
//       <Typography>
//         <b>Entries can be submitted by one of the following methods (use one only)</b>
//       </Typography>
//       <Typography>
//         <b>
//           Email <Contact name="qr" type="emailAddress" /> with the following:
//         </b>
//       </Typography>
//       <Typography>
//         <i>Name, School/Club, Gender, DOB, Age on day, Distance</i>
//       </Typography>
//       <Typography>Lengthy delays can be expected if entering on the day of the championship.</Typography>
//       <Typography>Age is taken as age on the day of the championship.</Typography>
//       <Typography>
//         <b>Registration Table:</b> Entries will be accepted at the registration table on Championship day.
//       </Typography>
//       <Typography>
//         Lengthy delays can sometimes be experienced at the registration desk. Preferred option is to register by email.
//         Please complete an entry form at the registration tent, or download the form below, fill it in and bring it with
//         you.
//       </Typography>
//       <Typography component="a" href={entryFormUrl} display="block">
//         2019 Winter Championship Entry Form
//       </Typography>
//       <Typography>
//         Runners wishing to enter after 9.30am on championship day, will need to do so at the registration table at 7th
//         Brigade Park (Geebung end).
//       </Typography>
//     </Section>
//   </>
// );

const EmptyAllSchoolsChampionships = () => null;

export const AllSchoolsChampionships = enhance(EmptyAllSchoolsChampionships);
