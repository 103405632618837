/* eslint-disable */
import * as Types from '../../../@generated/schema.d';

import { gql } from 'graphql.macro';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHoc from '@apollo/client/react/hoc';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CompetitionDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type CompetitionDetailsQuery = { readonly __typename?: 'Query' } & {
  readonly userAccount?: Types.Maybe<
    { readonly __typename?: 'UserAccount' } & Pick<
      Types.UserAccount,
      'id' | 'email' | 'firstName' | 'lastName' | 'phoneNumber' | 'postalAddress' | 'postcode' | 'suburb'
    >
  >;
  readonly runners?: Types.Maybe<
    { readonly __typename?: 'RunnersQueryResponse' } & {
      readonly data: ReadonlyArray<
        { readonly __typename?: 'Runner' } & Pick<
          Types.Runner,
          'id' | 'bibNumber' | 'firstName' | 'lastName' | 'dateOfBirth' | 'club' | 'gender' | 'membership' | 'runnerFee'
        >
      >;
    }
  >;
  readonly competition?: Types.Maybe<
    | ({ readonly __typename: 'AllSchoolsChampionships' } & Pick<
        Types.AllSchoolsChampionships,
        'id' | 'day' | 'agenda'
      >)
    | ({ readonly __typename: 'NoCompetition' } & Pick<Types.NoCompetition, 'id' | 'day' | 'agenda'>)
    | ({ readonly __typename: 'RockettRelays' } & Pick<
        Types.RockettRelays,
        'venueId' | 'status' | 'program' | 'id' | 'day' | 'agenda'
      > & {
          readonly fees?: Types.Maybe<
            { readonly __typename?: 'RockettRelaysFees' } & {
              readonly ONE_HOUR_RELAY: { readonly __typename?: 'RockettRelaysRelayFee' } & Pick<
                Types.RockettRelaysRelayFee,
                'nonWeeklyCompetitor' | 'weeklyCompetitor'
              >;
              readonly TWO_HOUR_RELAY: { readonly __typename?: 'RockettRelaysRelayFee' } & Pick<
                Types.RockettRelaysRelayFee,
                'nonWeeklyCompetitor' | 'weeklyCompetitor'
              >;
            }
          >;
          readonly rockettRelaysRegistration?: Types.Maybe<
            { readonly __typename?: 'RockettRelaysRegistration' } & Pick<
              Types.RockettRelaysRegistration,
              'contactDetailsCollectedDate' | 'runnerDetailsCollectedDate' | 'paymentCollectedDate'
            >
          >;
        })
    | ({ readonly __typename: 'WeeklyCompetition' } & Pick<
        Types.WeeklyCompetition,
        'venueId' | 'status' | 'program' | 'id' | 'day' | 'agenda'
      > & {
          readonly venue?: Types.Maybe<
            { readonly __typename?: 'Venue' } & Pick<Types.Venue, 'displayName' | 'name' | 'street' | 'url'>
          >;
          readonly fees?: Types.Maybe<
            { readonly __typename?: 'WeeklyCompetitionFees' } & {
              readonly TEN_AND_UNDER: { readonly __typename?: 'WeeklyCompetitionAgeGroupFees' } & Pick<
                Types.WeeklyCompetitionAgeGroupFees,
                'CASUAL_RUNNER' | 'SEASON_REGISTRATION' | 'PREPAID_REGISTRATION'
              >;
              readonly ELEVEN_TO_SEVENTEEN: { readonly __typename?: 'WeeklyCompetitionAgeGroupFees' } & Pick<
                Types.WeeklyCompetitionAgeGroupFees,
                'CASUAL_RUNNER' | 'SEASON_REGISTRATION' | 'PREPAID_REGISTRATION'
              >;
              readonly EIGHTEEN_AND_OLDER: { readonly __typename?: 'WeeklyCompetitionAgeGroupFees' } & Pick<
                Types.WeeklyCompetitionAgeGroupFees,
                'CASUAL_RUNNER' | 'SEASON_REGISTRATION' | 'PREPAID_REGISTRATION'
              >;
            }
          >;
          readonly weeklyCompetitionRegistration?: Types.Maybe<
            { readonly __typename?: 'WeeklyCompetitionRegistration' } & Pick<
              Types.WeeklyCompetitionRegistration,
              'id'
            > & {
                readonly runnerDetails?: Types.Maybe<
                  ReadonlyArray<
                    { readonly __typename?: 'WeeklyCompetitionRunnerDetails' } & Pick<
                      Types.WeeklyCompetitionRunnerDetails,
                      'firstName' | 'lastName' | 'bibNumber' | 'dateOfBirth' | 'club' | 'gender' | 'membership'
                    > & {
                        readonly item?: Types.Maybe<
                          { readonly __typename?: 'RunnerCatalogItem' } & Pick<
                            Types.RunnerCatalogItem,
                            'id' | 'price' | 'name'
                          >
                        >;
                      }
                  >
                >;
              }
          >;
        })
    | ({ readonly __typename: 'WinterChampionships' } & Pick<Types.WinterChampionships, 'id' | 'day' | 'agenda'>)
  >;
};

export type RegistrationQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type RegistrationQuery = { readonly __typename?: 'Query' } & {
  readonly registration?: Types.Maybe<
    | ({ readonly __typename: 'WeeklyCompetitionRegistration' } & Pick<Types.WeeklyCompetitionRegistration, 'id'> & {
          readonly contactDetails?: Types.Maybe<
            { readonly __typename?: 'ContactDetails' } & Pick<Types.ContactDetails, 'firstName' | 'lastName'>
          >;
          readonly runnerDetails?: Types.Maybe<
            ReadonlyArray<
              { readonly __typename?: 'WeeklyCompetitionRunnerDetails' } & Pick<
                Types.WeeklyCompetitionRunnerDetails,
                'firstName' | 'lastName' | 'bibNumber' | 'dateOfBirth' | 'club' | 'gender' | 'membership'
              > & {
                  readonly item?: Types.Maybe<
                    { readonly __typename?: 'RunnerCatalogItem' } & Pick<
                      Types.RunnerCatalogItem,
                      'id' | 'price' | 'name'
                    >
                  >;
                }
            >
          >;
        })
    | ({ readonly __typename: 'RockettRelaysRegistration' } & Pick<Types.RockettRelaysRegistration, 'id'> & {
          readonly contactDetails?: Types.Maybe<
            { readonly __typename?: 'ContactDetails' } & Pick<Types.ContactDetails, 'firstName' | 'lastName'>
          >;
          readonly teamRunners?: Types.Maybe<
            ReadonlyArray<
              { readonly __typename?: 'RockettRelaysRunnerDetails' } & Pick<
                Types.RockettRelaysRunnerDetails,
                'position' | 'dateOfBirth' | 'gender'
              >
            >
          >;
        })
  >;
};

export const CompetitionDetailsDocument = gql`
  query competitionDetails($id: String!) {
    userAccount {
      id
      email
      firstName
      lastName
      phoneNumber
      postalAddress
      postcode
      suburb
    }
    runners {
      data {
        id
        bibNumber
        firstName
        lastName
        dateOfBirth
        club
        gender
        membership
        runnerFee
      }
    }
    competition(id: $id) {
      __typename
      id
      day
      agenda
      ... on WeeklyCompetition {
        venueId
        status
        program
        venue {
          displayName
          name
          street
          url
        }
        fees {
          TEN_AND_UNDER {
            CASUAL_RUNNER
            SEASON_REGISTRATION
            PREPAID_REGISTRATION
          }
          ELEVEN_TO_SEVENTEEN {
            CASUAL_RUNNER
            SEASON_REGISTRATION
            PREPAID_REGISTRATION
          }
          EIGHTEEN_AND_OLDER {
            CASUAL_RUNNER
            SEASON_REGISTRATION
            PREPAID_REGISTRATION
          }
        }
        weeklyCompetitionRegistration {
          id
          runnerDetails {
            firstName
            lastName
            bibNumber
            dateOfBirth
            club
            gender
            membership
            item {
              id
              price
              name
            }
          }
        }
      }
      ... on RockettRelays {
        venueId
        status
        program
        fees {
          ONE_HOUR_RELAY {
            nonWeeklyCompetitor
            weeklyCompetitor
          }
          TWO_HOUR_RELAY {
            nonWeeklyCompetitor
            weeklyCompetitor
          }
        }
        rockettRelaysRegistration {
          contactDetailsCollectedDate
          runnerDetailsCollectedDate
          paymentCollectedDate
        }
      }
    }
  }
`;
export type CompetitionDetailsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CompetitionDetailsQuery, CompetitionDetailsQueryVariables>,
  'query'
> &
  ({ variables: CompetitionDetailsQueryVariables; skip?: boolean } | { skip: boolean });

export const CompetitionDetailsComponent = (props: CompetitionDetailsComponentProps) => (
  <ApolloReactComponents.Query<CompetitionDetailsQuery, CompetitionDetailsQueryVariables>
    query={CompetitionDetailsDocument}
    {...props}
  />
);

export type CompetitionDetailsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<CompetitionDetailsQuery, CompetitionDetailsQueryVariables>;
} &
  TChildProps;
export function withCompetitionDetails<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CompetitionDetailsQuery,
    CompetitionDetailsQueryVariables,
    CompetitionDetailsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CompetitionDetailsQuery,
    CompetitionDetailsQueryVariables,
    CompetitionDetailsProps<TChildProps, TDataName>
  >(CompetitionDetailsDocument, {
    alias: 'competitionDetails',
    ...operationOptions,
  });
}

/**
 * __useCompetitionDetailsQuery__
 *
 * To run a query within a React component, call `useCompetitionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompetitionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompetitionDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompetitionDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<CompetitionDetailsQuery, CompetitionDetailsQueryVariables>,
) {
  return Apollo.useQuery<CompetitionDetailsQuery, CompetitionDetailsQueryVariables>(
    CompetitionDetailsDocument,
    baseOptions,
  );
}
export function useCompetitionDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CompetitionDetailsQuery, CompetitionDetailsQueryVariables>,
) {
  return Apollo.useLazyQuery<CompetitionDetailsQuery, CompetitionDetailsQueryVariables>(
    CompetitionDetailsDocument,
    baseOptions,
  );
}
export type CompetitionDetailsQueryHookResult = ReturnType<typeof useCompetitionDetailsQuery>;
export type CompetitionDetailsLazyQueryHookResult = ReturnType<typeof useCompetitionDetailsLazyQuery>;
export type CompetitionDetailsQueryResult = Apollo.QueryResult<
  CompetitionDetailsQuery,
  CompetitionDetailsQueryVariables
>;
export const RegistrationDocument = gql`
  query registration($id: String!) {
    registration(id: $id) {
      __typename
      ... on WeeklyCompetitionRegistration {
        id
        contactDetails {
          firstName
          lastName
        }
        runnerDetails {
          firstName
          lastName
          bibNumber
          dateOfBirth
          club
          gender
          membership
          item {
            id
            price
            name
          }
        }
      }
      ... on RockettRelaysRegistration {
        id
        contactDetails {
          firstName
          lastName
        }
        teamRunners: runnerDetails {
          position
          dateOfBirth
          gender
        }
      }
    }
  }
`;
export type RegistrationComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<RegistrationQuery, RegistrationQueryVariables>,
  'query'
> &
  ({ variables: RegistrationQueryVariables; skip?: boolean } | { skip: boolean });

export const RegistrationComponent = (props: RegistrationComponentProps) => (
  <ApolloReactComponents.Query<RegistrationQuery, RegistrationQueryVariables> query={RegistrationDocument} {...props} />
);

export type RegistrationProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<RegistrationQuery, RegistrationQueryVariables>;
} &
  TChildProps;
export function withRegistration<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RegistrationQuery,
    RegistrationQueryVariables,
    RegistrationProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    RegistrationQuery,
    RegistrationQueryVariables,
    RegistrationProps<TChildProps, TDataName>
  >(RegistrationDocument, {
    alias: 'registration',
    ...operationOptions,
  });
}

/**
 * __useRegistrationQuery__
 *
 * To run a query within a React component, call `useRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegistrationQuery(
  baseOptions: Apollo.QueryHookOptions<RegistrationQuery, RegistrationQueryVariables>,
) {
  return Apollo.useQuery<RegistrationQuery, RegistrationQueryVariables>(RegistrationDocument, baseOptions);
}
export function useRegistrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RegistrationQuery, RegistrationQueryVariables>,
) {
  return Apollo.useLazyQuery<RegistrationQuery, RegistrationQueryVariables>(RegistrationDocument, baseOptions);
}
export type RegistrationQueryHookResult = ReturnType<typeof useRegistrationQuery>;
export type RegistrationLazyQueryHookResult = ReturnType<typeof useRegistrationLazyQuery>;
export type RegistrationQueryResult = Apollo.QueryResult<RegistrationQuery, RegistrationQueryVariables>;
