import React from 'react';
import { PageTitle } from '@queensland-running/qr-components';
import { Typography } from '@material-ui/core';
import { Contact } from '@components/contacts';
import { RouteLabels } from '@constants';

const VolunteerView = () => (
  <>
    <PageTitle title={RouteLabels.volunteer} />
    <Typography gutterBottom>
      Queensland Running is a volunteer-based organisation and relies heavily on the goodwill of those who regularly
      volunteer. Due to the growth and related success of our weekly events, we require more volunteers at each Saturday
      afternoon event to enable us to continue to deliver our quality friendly service.
    </Typography>
    <Typography gutterBottom>
      <b>
        ** Urgently needed ** course marking assistance from 11am - 1pm for each round and course marshalls during the
        program.
      </b>
    </Typography>
    <br />
    <Typography variant="h2" color="primary" gutterBottom>
      Roles
    </Typography>
    <Typography variant="body1">We are seeking volunteers to carry out the following:</Typography>
    <ul>
      <li>
        <Typography>
          <b>Course marking:</b> assist course setters to mark the course by placing flags and signs
        </Typography>
      </li>
      <li>
        <Typography>
          <b>Registration:</b> take season registrations and fees, take weekly entries and fees, assist runners with the
          registration/entry process
        </Typography>
      </li>
      <li>
        <Typography>
          <b>Results:</b> write athlete numbers on results sheet, write times from stopwatch tape on to results sheet
        </Typography>
      </li>
      <li>
        <Typography>
          <b>Finish:</b> ensure all athletes enter finish shute and stay in order until number is recorded, assist
          results recorder
        </Typography>
      </li>
      <li>
        <Typography>
          <b>Course:</b> positioned on the course to direct runners and assist them to follow the course correctly
        </Typography>
      </li>
      <li>
        <Typography>
          <b>End of day:</b> collecting flags and signs
        </Typography>
      </li>
    </ul>
    <br />
    <Typography variant="h2" color="primary" gutterBottom>
      Hours
    </Typography>
    <Typography variant="body1" gutterBottom>
      Volunteers are asked to assist between 1.30pm and 4.00pm on a Saturday afternoon. Course markers
      are required from 11:00am - 12:30pm. Whatever time you have available though, will be gratefully, even 30 minutes will be of help.
    </Typography>
    <br />
    <Typography variant="h2" color="primary" gutterBottom>
      How to volunteer
    </Typography>
    Please email <Contact name="qr" type="emailAddress" /> with any suggestions
  </>
);

export const Volunteer = VolunteerView;
