import {Grid, Typography} from '@material-ui/core';
import React, {Fragment} from 'react';
import {PageTitle} from '@queensland-running/qr-components';
import styled from 'styled-components';
import {useTranslation} from "react-i18next";

const Image = styled('img')`
  margin: auto;
  display: block;
  max-width: 100%;
  max-height: 100%;
  padding-bottom: 1rem;
`;

type CourseSignageType = {
    imageSrc: string,
    text?: string
    list?: string[]
}

const CourseSignageView = () => {
    const {t} = useTranslation('common')
    const courseSignage = t<string, CourseSignageType[]>('courseSignage.content', {returnObjects: true})

    return (
        <>
            <PageTitle title={t('app.routes.courseSignage')}/>
            <Grid container spacing={3}>
                {
                    courseSignage.map(({imageSrc, text, list}, position) =>
                        <Fragment key={position}>
                            {imageSrc && <Grid item xs={12} sm={6}>
                                <Image src={imageSrc}/>
                            </Grid>
                            }
                            {
                                text && (
                                    <Grid item xs={12} sm={6}>
                                        <Typography>{text}</Typography>
                                    </Grid>
                                )
                            }
                            {list && (
                                <Grid item xs={12} sm={6}>
                                    <ul style={{listStyleType: 'none', padding: 0, margin: 0}}>
                                        {
                                            list.map((listIndex, position) => (
                                                <li key={position}>
                                                    <Typography>{listIndex}</Typography>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </Grid>
                            )
                            }
                        </Fragment>)
                }
            </Grid>
        </>
    );
};

export const CourseSignage = CourseSignageView;
