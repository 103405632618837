import {Redirect, Route, Switch} from 'react-router';
import {RouteKeys, Routes} from '../@constants';
import {About} from './pages/about';
import {Account} from './pages/account';
import {Runners} from './pages/runners';
import {CourseSignage} from './pages/course-signage/course-signage';
import {Calendar, Event, Events} from './pages/event';
import {VenuePage as Venue, Venues} from './pages/venue';
import {ProgramPage} from './pages/programs/programs';
import {Volunteer} from './pages/volunteer';
import Registration from './pages/information/registration';
import {ComingSoon, Home} from './pages/home';
import React from 'react';
import {PrivateRoute} from './private-route';
import EventRegistrationFlow from './pages/competition-registration/event-registration-flow';
import {useConfig} from '../@constants/config';

const oldRedirects = {
  '#/about': Routes.about,
  '#/weekly-comp/calendar': Routes.calendar.replace(':year', '2023'),
  '#/weekly-comp/results': Routes.calendar.replace(':year', '2023'),
  '#/weekly-comp/programs': Routes.programs,
  '#/venues': Routes.venues,
  '#/championships': Routes.events,
  '#/championships/all-schools-championship': Routes.allSchoolsChampionships,
  '#/championships/winter-championship': Routes.winterChampionships,
  '#/championships/rockett-relays': Routes.rockettRelays,
};

const AppRoutes = () => {
  const { toggles } = useConfig();
  if (toggles.maintenanceMode) {
    return (
      <Switch>
        <Route component={ComingSoon} />
      </Switch>
    );
  }

  return (
    <Switch>
      {/*<Route exact path={'/auth'} component={Authenticator} />*/}
      <Route exact path={Routes.about} component={About} />
      <PrivateRoute exact path={Routes.account} component={Account} />
      <PrivateRoute exact path={Routes.runners} component={Runners} />
      <Route exact path={Routes.courseSignage} component={CourseSignage} />
      <Route exact path={Routes.events} component={Events} />
      <Route
        exact
        path={Routes.allSchoolsChampionships}
        render={({ location }) => {
          location.search = '?competitionId=2021-ASC';
          return <Event />;
        }}
      />
      <Route
        exact
        path={Routes.winterChampionships}
        render={({ location }) => {
          location.search = '?competitionId=2021-WC';
          return <Event />;
        }}
      />
      <Route
        exact
        path={Routes.rockettRelays}
        render={({ location }) => {
          location.search = '?competitionId=2021-RR';
          return <Event />;
        }}
      />
      <Route exact path={Routes.calendar} component={Calendar} />
      <Route exact path={Routes.event} component={Event} />
      <Route exact path={Routes.fees} component={About} />
      <Route exact path={Routes.venues} component={Venues} />
      <Route exact path={Routes.venue} component={Venue} />
      <Route exact path={Routes.programs} component={ProgramPage} />
      <Route exact path={Routes.volunteer} component={Volunteer} />
      <Route exact path={Routes.registration} component={Registration} />
      <Route exact path={'/stepper'} component={EventRegistrationFlow} />
      <Route
        render={({ location }) => {
          // @ts-ignore
          const getRedirect = oldRedirects[location.hash];
          if (getRedirect) {
            return <Redirect to={{ pathname: getRedirect }} />;
          } else {
            location.pathname = Routes.appBase;
            return <Route exact path={Routes.appBase} component={Home} />;
          }
        }}
      />
    </Switch>
  );
};

export { AppRoutes };
