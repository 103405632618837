import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useUpdateAccountMutation } from './mutations.generated';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { Button, ContactNumber } from '@queensland-running/qr-components';

type UpdateDetailsProps = {
  isOpen: boolean;
  onClose: () => void;
};
type UpdateDetailsModalViewProps = UpdateDetailsProps & {
  initialValues: any;
};

const isValidPhoneNumber = (number: string, country: string): boolean => {
  try {
    const ph = number.replace(/ /g, '');
    const phoneUtil = PhoneNumberUtil.getInstance(),
      phoneNumber = phoneUtil.parseAndKeepRawInput(ph, country),
      isValid = phoneUtil.isValidNumberForRegion(phoneNumber, country),
      formatted = phoneUtil.format(phoneNumber, PhoneNumberFormat.E164);
    return isValid && formatted === ph ? true : false;
  } catch (e) {
    return false;
  }
};

export const phone = (country: string) =>
  // @ts-ignore
  string().test('phone', 'Not a valid Australian number beginning with +61', (value: string) =>
    value && country ? isValidPhoneNumber(value, country) : true,
  );

const UpdateDetailsModalView = ({ onClose, isOpen, initialValues }: UpdateDetailsModalViewProps) => {
  const [updateDetails] = useUpdateAccountMutation({
    onCompleted: () => {
      onClose();
    },
  });

  const {
    errors,
    isValid,
    values,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      firstName: initialValues.firstName || '',
      lastName: initialValues.lastName || '',
      phoneNumber: initialValues.phoneNumber || '',
      // address: initialValues.address || '',
    },
    validationSchema: object().shape({
      firstName: string().required('A first name is required'),
      lastName: string().required('A last name is required'),
      phoneNumber: phone('AU').required('A phone number is required'),
      // address: string().required('A contact address is required'),
    }),
    onSubmit: (values) => {
      updateDetails({
        variables: {
          input: {
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber === '' ? null : values.phoneNumber.replace(/ /g, ''),
            // address: values.address === '' ? null : values.address,
          },
        },
      });
    },
  });

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        resetForm();
        onClose();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <form onSubmit={handleSubmit}>
        <DialogTitle id="alert-dialog-title">Change Details</DialogTitle>
        <DialogContent id="alert-dialog-description">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                error={!!(errors.firstName && touched.firstName)}
                label="First Name"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.firstName && touched.firstName && errors.firstName}
                disabled={isSubmitting}
                margin="normal"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={!!(errors.lastName && touched.lastName)}
                required
                label="Last Name"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.lastName && touched.lastName && errors.lastName}
                disabled={isSubmitting}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ContactNumber
                required
                error={!!(errors.phoneNumber && touched.phoneNumber)}
                label="Contact Number"
                name="phoneNumber"
                value={values.phoneNumber}
                onChange={(e) => {
                  setFieldValue('phoneNumber', e);
                }}
                onBlur={handleBlur}
                helperText={errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
                disabled={isSubmitting}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            title={'Close'}
            color="primary"
            autoFocus
            onClick={() => {
              resetForm();
              onClose();
            }} />
          <Button color="primary" type="submit" variant="contained" disabled={!isValid || isSubmitting} title='Update'/>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export const UpdateDetailsModal = React.memo(UpdateDetailsModalView);
