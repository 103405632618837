/* eslint-disable */
import * as Types from '../../@generated/schema.d';

import { gql } from 'graphql.macro';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHoc from '@apollo/client/react/hoc';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateRunnerMutationVariables = Types.Exact<{
  input: Types.CreateRunnerInput;
}>;

export type CreateRunnerMutation = { readonly __typename?: 'Mutation' } & {
  readonly runners?: Types.Maybe<
    { readonly __typename?: 'RunnersMutation' } & {
      readonly createRunner?: Types.Maybe<
        { readonly __typename?: 'Runner' } & Pick<
          Types.Runner,
          'id' | 'bibNumber' | 'firstName' | 'lastName' | 'dateOfBirth' | 'club' | 'gender' | 'status'
        >
      >;
    }
  >;
};

export type UpdateRunnerMutationVariables = Types.Exact<{
  input: Types.UpdateRunnerInput;
}>;

export type UpdateRunnerMutation = { readonly __typename?: 'Mutation' } & {
  readonly runners?: Types.Maybe<
    { readonly __typename?: 'RunnersMutation' } & {
      readonly updateRunner?: Types.Maybe<{ readonly __typename?: 'Runner' } & Pick<Types.Runner, 'id' | 'club'>>;
    }
  >;
};

export type UpdateAccountMutationVariables = Types.Exact<{
  input: Types.UpdateUserAccountInput;
}>;

export type UpdateAccountMutation = { readonly __typename?: 'Mutation' } & {
  readonly userAccount?: Types.Maybe<
    { readonly __typename?: 'UserAccountMutation' } & {
      readonly updateAccount?: Types.Maybe<
        { readonly __typename?: 'UserAccount' } & Pick<
          Types.UserAccount,
          'id' | 'firstName' | 'lastName' | 'phoneNumber'
        >
      >;
    }
  >;
};

export const CreateRunnerDocument = gql`
  mutation createRunner($input: CreateRunnerInput!) {
    runners {
      createRunner(input: $input) {
        id
        bibNumber
        firstName
        lastName
        dateOfBirth
        club
        gender
        status
      }
    }
  }
`;
export type CreateRunnerMutationFn = Apollo.MutationFunction<CreateRunnerMutation, CreateRunnerMutationVariables>;
export type CreateRunnerComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateRunnerMutation, CreateRunnerMutationVariables>,
  'mutation'
>;

export const CreateRunnerComponent = (props: CreateRunnerComponentProps) => (
  <ApolloReactComponents.Mutation<CreateRunnerMutation, CreateRunnerMutationVariables>
    mutation={CreateRunnerDocument}
    {...props}
  />
);

export type CreateRunnerProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<CreateRunnerMutation, CreateRunnerMutationVariables>;
} &
  TChildProps;
export function withCreateRunner<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateRunnerMutation,
    CreateRunnerMutationVariables,
    CreateRunnerProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateRunnerMutation,
    CreateRunnerMutationVariables,
    CreateRunnerProps<TChildProps, TDataName>
  >(CreateRunnerDocument, {
    alias: 'createRunner',
    ...operationOptions,
  });
}

/**
 * __useCreateRunnerMutation__
 *
 * To run a mutation, you first call `useCreateRunnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRunnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRunnerMutation, { data, loading, error }] = useCreateRunnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRunnerMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRunnerMutation, CreateRunnerMutationVariables>,
) {
  return Apollo.useMutation<CreateRunnerMutation, CreateRunnerMutationVariables>(CreateRunnerDocument, baseOptions);
}
export type CreateRunnerMutationHookResult = ReturnType<typeof useCreateRunnerMutation>;
export type CreateRunnerMutationResult = Apollo.MutationResult<CreateRunnerMutation>;
export type CreateRunnerMutationOptions = Apollo.BaseMutationOptions<
  CreateRunnerMutation,
  CreateRunnerMutationVariables
>;
export const UpdateRunnerDocument = gql`
  mutation updateRunner($input: UpdateRunnerInput!) {
    runners {
      updateRunner(input: $input) {
        id
        club
      }
    }
  }
`;
export type UpdateRunnerMutationFn = Apollo.MutationFunction<UpdateRunnerMutation, UpdateRunnerMutationVariables>;
export type UpdateRunnerComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateRunnerMutation, UpdateRunnerMutationVariables>,
  'mutation'
>;

export const UpdateRunnerComponent = (props: UpdateRunnerComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateRunnerMutation, UpdateRunnerMutationVariables>
    mutation={UpdateRunnerDocument}
    {...props}
  />
);

export type UpdateRunnerProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<UpdateRunnerMutation, UpdateRunnerMutationVariables>;
} &
  TChildProps;
export function withUpdateRunner<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateRunnerMutation,
    UpdateRunnerMutationVariables,
    UpdateRunnerProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateRunnerMutation,
    UpdateRunnerMutationVariables,
    UpdateRunnerProps<TChildProps, TDataName>
  >(UpdateRunnerDocument, {
    alias: 'updateRunner',
    ...operationOptions,
  });
}

/**
 * __useUpdateRunnerMutation__
 *
 * To run a mutation, you first call `useUpdateRunnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRunnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRunnerMutation, { data, loading, error }] = useUpdateRunnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRunnerMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRunnerMutation, UpdateRunnerMutationVariables>,
) {
  return Apollo.useMutation<UpdateRunnerMutation, UpdateRunnerMutationVariables>(UpdateRunnerDocument, baseOptions);
}
export type UpdateRunnerMutationHookResult = ReturnType<typeof useUpdateRunnerMutation>;
export type UpdateRunnerMutationResult = Apollo.MutationResult<UpdateRunnerMutation>;
export type UpdateRunnerMutationOptions = Apollo.BaseMutationOptions<
  UpdateRunnerMutation,
  UpdateRunnerMutationVariables
>;
export const UpdateAccountDocument = gql`
  mutation updateAccount($input: UpdateUserAccountInput!) {
    userAccount {
      updateAccount(input: $input) {
        id
        firstName
        lastName
        phoneNumber
      }
    }
  }
`;
export type UpdateAccountMutationFn = Apollo.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;
export type UpdateAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateAccountMutation, UpdateAccountMutationVariables>,
  'mutation'
>;

export const UpdateAccountComponent = (props: UpdateAccountComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateAccountMutation, UpdateAccountMutationVariables>
    mutation={UpdateAccountDocument}
    {...props}
  />
);

export type UpdateAccountProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;
} &
  TChildProps;
export function withUpdateAccount<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateAccountMutation,
    UpdateAccountMutationVariables,
    UpdateAccountProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateAccountMutation,
    UpdateAccountMutationVariables,
    UpdateAccountProps<TChildProps, TDataName>
  >(UpdateAccountDocument, {
    alias: 'updateAccount',
    ...operationOptions,
  });
}

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>,
) {
  return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, baseOptions);
}
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;
