const getEnv = (key: string): string => {
  const env = process.env[key];

  if (!env) {
    throw new Error(`Missing environment variable ${key}`);
  }

  if (process.env.REACT_APP_STAGE === 'production') {
  } else {
    // console.log(`Loading environment variable ${key}: ${JSON.stringify(env)}`);
  }

  return env;
};

// export const currentVersion = getEnv('REACT_APP_VERSION');
export const graphqlApiUri = getEnv('REACT_APP_GRAPHQL_API_URI');
export const region = getEnv('REACT_APP_REGION');
export const stage = getEnv('REACT_APP_STAGE');
export const userPoolId = getEnv('REACT_APP_USER_POOL_ID');
export const userPoolWebClientId = getEnv('REACT_APP_USER_POOL_WEB_CLIENT_ID');
export const sentryDsn = getEnv('REACT_APP_SENTRY_DSN');