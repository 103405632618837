import React, { useContext, useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Button, LoadingComponent, UnstyledLink } from '@queensland-running/qr-components';
import { StepProps } from '../event-registration-flow';
import { Section } from '@components/section/section';
import { Grid } from '@material-ui/core';
import UserContext from '../../../../UserContext';
import {
  AllSchoolsChampionships,
  RockettRelays,
  RockettRelaysRegistration,
  WeeklyCompetition,
  WeeklyCompetitionRegistration,
  WinterChampionships,
} from '@generated/schema';
import { CompetitionDetailsQuery, useCompetitionDetailsQuery } from '../queries.generated';

export type CompetitionType = WeeklyCompetition | RockettRelays | AllSchoolsChampionships | WinterChampionships;

interface RegisterForEventProps {
  competitionId: string;
}

const getRegistration = (
  competition?: CompetitionDetailsQuery['competition'],
): WeeklyCompetitionRegistration | RockettRelaysRegistration | undefined | null => {
  switch (competition?.__typename) {
    case 'WeeklyCompetition':
      return competition?.weeklyCompetitionRegistration as WeeklyCompetitionRegistration;
    case 'RockettRelays':
      return competition?.rockettRelaysRegistration as RockettRelaysRegistration;
    default:
      return undefined;
  }
};

const useRegisterForEvent = ({ competitionId }: RegisterForEventProps) => {
  const [registration, setRegistration] = useState<
    WeeklyCompetitionRegistration | RockettRelaysRegistration | undefined | null
  >();
  const { data: eventData, loading } = useCompetitionDetailsQuery({
    variables: {
      id: competitionId,
    },
  });

  useEffect(() => {
    const registrationData = getRegistration(eventData?.competition);
    setRegistration(registrationData);
    console.log(registrationData);
  }, [eventData]);
  return { competition: eventData?.competition, registration, userAccount: eventData?.userAccount, loading };
};
export const PreviousRegistrationStep = ({ handleNext, competitionId }: StepProps) => {
  const { user } = useContext(UserContext);
  const { competition, registration, loading } = useRegisterForEvent({ competitionId });
  if (loading) {
    return (
      <>
        <br />
        <LoadingComponent message="Loading..." />
      </>
    );
  }
  if (!competition) {
    return (
      <>
        <br />
        <>No competition selected</>
      </>
    );
  }
  if (!registration) {
    handleNext!();
  }

  return (
    <>
      {user ? (
        <div>{JSON.stringify(registration)}</div>
      ) : (
        <>
          <br />

          <Section verticalSpacing>
            <Typography>If you are registering to this event with multiple individuals, please sign in.</Typography>
          </Section>
          <br />
          <Grid container spacing={3} direction="column" alignItems="center">
            <UnstyledLink to={'/auth'}>
              <Button variant="contained" color="secondary" title="Sign In" />
            </UnstyledLink>
          </Grid>
        </>
      )}
    </>
  );
};
