import {
  setupFacebook, LoadScriptProps
} from "./setup-facebook";

const loadScript = ({ id, setupScriptFunction, documentSection = 'body' }: LoadScriptProps) => {
  const scriptExists = document.getElementById(id)

  if (!scriptExists) {
    const script = document.createElement('script')
    script.id = id
    setupScriptFunction(script)

    document[documentSection].appendChild(script)
  }
}


const setupThirdParties = () => {
  setupFacebook(loadScript)
}

export { setupThirdParties }