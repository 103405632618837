import React, {useEffect} from 'react';
import {LoadingComponent} from '@queensland-running/qr-components';
import {Runner} from '@generated/schema';
import {StepProps} from '../event-registration-flow';
import {useCompetitionDetailsQuery} from '../queries.generated';
import {useRegistration} from '@constants/registration-context';
import {RockettRelayRegistration} from './runner-registration/rockett-relay-registration';
import {WeeklyRegistration} from "./runner-registration/weekly-registration";

interface RegisterForEventProps {
  competitionId: string;
}

const useRegisterForEvent = ({competitionId}: RegisterForEventProps) => {
  const {data, loading} = useCompetitionDetailsQuery({
    variables: {
      id: competitionId!,
    },
  });

  return {
    runners: (data?.runners?.data! as Runner[]) ?? [],
    competition: data?.competition,
    userAccount: data?.userAccount,
    loading,
  };
};
export const SelectRunnersStep = (props: StepProps) => {
  const {registrationId} = useRegistration();
  const {competition, loading} = useRegisterForEvent({competitionId: props.competitionId});

  useEffect(() => {
    if (!registrationId) {
      const timer = setTimeout(() => {
        props.handleBack!()
      }, 1500)
      return () => clearTimeout(timer)
    }
  }, [registrationId, props.handleBack])

  if (loading || !registrationId) {
    return (
      <>
        <br/>
        <LoadingComponent message="Confirming runner details..."/>
      </>
    );
  }

  switch (competition?.__typename) {
    case 'WeeklyCompetition':
      return <WeeklyRegistration {...props} />;
    case 'RockettRelays':
      return <RockettRelayRegistration {...props} />;
    default:
      return null;
  }
};
