import {AllSchoolsChampionships, WinterChampionships, RockettRelays, WeeklyEvent} from "./details";
import React from "react";

const EventDetails = ({agenda}: { agenda: string | undefined }): JSX.Element | null => {
    if (!agenda) {
        return null
    }

    const eventDetails: Record<string, JSX.Element> = {
        'All Schools Championships': <AllSchoolsChampionships/>,
        'Winter Championships': <WinterChampionships/>,
        'Rockett Relays': <RockettRelays/>,
    }

    return eventDetails[agenda] ?? <WeeklyEvent/>
}

export { EventDetails }