export default {
  ascott: {
    name: 'Anne Scott',
    phoneNumber: '0418 113 377',
    emailAddress: 'anne113377@gmail.com',
  },
  jolsen: {
    name: 'Jacob',
    emailAddress: 'jacob.queenslandrunning@gmail.com',
  },
  siselin: {
    name: 'Steve Iselin',
    emailAddress: 'qldrunningresults@outlook.com',
  },
  qr: {
    name: 'queenslandrunning@gmail.com',
    phoneNumber: '0418 113 377',
    emailAddress: 'queenslandrunning@gmail.com',
  },
};
