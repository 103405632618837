import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {PageTitle, ProgramTable} from '@queensland-running/qr-components';
import {useTranslation} from "react-i18next";
import {usePrograms} from "@components";

export const ProgramPage = () => {
    const {t} = useTranslation('common')
    const { programs } = usePrograms()

    return (
        <>
            <PageTitle title={t('app.routes.programs')}/>
            <Grid container spacing={3}>
                {
                    programs.map(({name, program}) => (
                        <Grid item xs={12} md={6} key={name}>
                            <Typography variant="h5" color={'secondary'}>
                                {name}
                            </Typography>
                            <ProgramTable programItems={program}/>
                        </Grid>
                    ))
                }
            </Grid>
        </>
    );
};
