import React, { useState } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import {
  Button,
  ButtonWrapper,
  ErrorComponent,
  FieldValue,
  LoadingComponent,
  PageTitle,
  UnstyledLink,
} from '@queensland-running/qr-components';
import { Grid, Typography } from '@material-ui/core';
import { useFetchAccountQuery } from './queries.generated';
import { UpdateDetailsModal } from '@containers/modals/update-details-modal';
import { Section } from '@components/section/section';
import { Contact } from '@components/contacts';
import { RouteLabels, Routes } from '@constants';

const enhance = compose(withRouter, React.memo);

const AccountView = () => {
  const [changeDetailsModel, openChangeDetailsModel] = useState<boolean>(false);

  const { data, loading, error } = useFetchAccountQuery();

  if (loading) {
    return <LoadingComponent />;
  }

  if (error) {
    return <ErrorComponent />;
  }

  const account = data!.userAccount;
  if (!account) {
    return <></>
  }

  const firstName = account.firstName;
  const lastName = account.lastName;
  const email = account.email;
  const phoneNumber = account.phoneNumber;

  return (
    <>
      <PageTitle title={RouteLabels.account} />
      <Grid container>
        <Grid item xs={12}>
          <Section verticalSpacing>
            <Typography>
              Welcome to <i>Account Details</i>. On this page, you can manage your contact details.
            </Typography>
            <Typography>
              Contact <Contact name={'jolsen'} type={'emailAddress'} /> with any issues or concerns regarding your
              account.
            </Typography>
          </Section>
        </Grid>
      </Grid>
      <Grid container wrap="nowrap" spacing={3}>
        <Grid item xs zeroMinWidth>
          <Typography variant={'h2'} noWrap>
            {firstName} {lastName}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <FieldValue label="Email" name="email" value={email} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldValue label="First Name" name="firstName" value={firstName} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldValue label="Last Name" name="lastName" value={lastName} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldValue label="Contact Number" name="contactNumber" value={phoneNumber} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>
            <Typography variant="overline" color="secondary">
              Actions
            </Typography>
            <br />
            <ButtonWrapper>
              <Button color="primary" type="submit" variant="contained" onClick={() => openChangeDetailsModel(true)} title='Change details'/>
              <UnstyledLink to={Routes.runners}>
                <Button color="primary" type="submit" variant="contained" title='Manage runners'/>
              </UnstyledLink>
            </ButtonWrapper>
          </div>
        </Grid>
      </Grid>
      {changeDetailsModel && (
        <UpdateDetailsModal
          isOpen={changeDetailsModel}
          onClose={() => openChangeDetailsModel(false)}
          initialValues={{
            firstName,
            lastName,
            phoneNumber,
          }}
        />
      )}
    </>
  );
};

export const Account = enhance(AccountView);
