import React from 'react';
import {useConfig} from "../../../../@constants/config";
// import {Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";

const WeeklyEvent = () => {
    const {toggles} = useConfig()

    return (
        <>
            {/*{*/}
            {/*    toggles.googleForm && <iframe*/}
            {/*        src="https://docs.google.com/forms/d/e/1FAIpQLSezofo5hAF5xuBK2abxiGIjzJ1h_RLBknffywXX6IAkmxIOFw/viewform?embedded=true"*/}
            {/*        width="640" height="1204" frameBorder="0" marginHeight={0}*/}
            {/*        marginWidth={0}>Loading…</iframe>*/}
            {/*}*/}

            {/*<Typography variant="h5" color="secondary">*/}
            {/*  Weekly Registration (2021 Season)*/}
            {/*</Typography>*/}
            {/*<Typography>Come along and run on the day and pay</Typography>*/}
            {/*<Table size="small">*/}
            {/*  <TableHead>*/}
            {/*    <TableRow>*/}
            {/*      <TableCell>Age</TableCell>*/}
            {/*      <TableCell>Fee</TableCell>*/}
            {/*    </TableRow>*/}
            {/*  </TableHead>*/}
            {/*  <TableBody>*/}
            {/*    <TableRow>*/}
            {/*      <TableCell>10 years and under</TableCell>*/}
            {/*      <TableCell>$3.00</TableCell>*/}
            {/*      /!*<TableCell>FREE</TableCell>*!/*/}
            {/*    </TableRow>*/}
            {/*    <TableRow>*/}
            {/*      <TableCell>11-17 years</TableCell>*/}
            {/*      <TableCell>$4.00</TableCell>*/}
            {/*      /!*<TableCell>FREE</TableCell>*!/*/}
            {/*    </TableRow>*/}
            {/*    <TableRow>*/}
            {/*      <TableCell>18+ years</TableCell>*/}
            {/*      <TableCell>$5.00</TableCell>*/}
            {/*      /!*<TableCell>FREE</TableCell>*!/*/}
            {/*    </TableRow>*/}
            {/*  </TableBody>*/}
            {/*</Table>*/}
            {/*<UnstyledLink to={Routes.registration}>*/}
            {/*  <Button color="primary" size="small">*/}
            {/*    View other registration options*/}
            {/*  </Button>*/}
            {/*</UnstyledLink>*/}
            {/*<br />*/}
            {/*<Typography variant="h5" color="secondary">*/}
            {/*  /!*Registering and payment*!/*/}
            {/*  Registration*/}
            {/*</Typography>*/}
            {/*<Typography>For 2020 Registered and Casual runners there are three options.</Typography>*/}
            {/*<br />*/}
            {/*<Typography variant="h6">Register online</Typography>*/}
            {/*<Section verticalSpacing>*/}
            {/*  <Typography>*/}
            {/*    Complete the registration form on this page by 9:00am Saturday morning - preferably by Friday night.*/}
            {/*  </Typography>*/}
            {/*  <Typography>*/}
            {/*    You will receive a short confirmation email by 9:15am Saturday advising of receipt of your entry.*/}
            {/*  </Typography>*/}
            {/*  <Typography>*/}
            {/*    When you arrive, please advise the volunteers at the Registration Tent that you entered online.*/}
            {/*    /!*and pay the appropriate fee.*!/*/}
            {/*  </Typography>*/}
            {/*</Section>*/}
            {/*<Typography variant="h6">Register by email</Typography>*/}
            {/*<Section verticalSpacing>*/}
            {/*  <Typography>*/}
            {/*    Email <Contact name="qr" type="emailAddress" /> with the following details by 9:00am Saturday morning -*/}
            {/*    preferably by Friday night.*/}
            {/*  </Typography>*/}
            {/*  <Typography>*/}
            {/*    <b>Subject:</b> Queensland Running - Weekly Entry*/}
            {/*  </Typography>*/}
            {/*  <Typography>*/}
            {/*    <b>Name – School/Club/Independent – Age – Date of Birth – Gender</b>*/}
            {/*  </Typography>*/}
            {/*  <Typography>*/}
            {/*    You will receive a short confirmation email by 9:15am Saturday advising of receipt of your entry.*/}
            {/*  </Typography>*/}
            {/*  <Typography>*/}
            {/*    When you arrive, please advise the volunteers at the Registration Tent that you entered online.*/}
            {/*    /!*and pay the appropriate fee.*!/*/}
            {/*  </Typography>*/}
            {/*</Section>*/}
            {/*<Typography variant="h6">Register in person</Typography>*/}
            {/*<Typography>*/}
            {/*  Fill out a weekly entry form at the Registration Tent or download the form from the website and give it to a*/}
            {/*  volunteer.*/}
            {/*  /!*along with the appropriate fee.*!/*/}
            {/*</Typography>*/}
        </>
    );
};

export {WeeklyEvent}
