import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { object } from 'yup';
import { UpdateRunnerMutation, useUpdateRunnerMutation } from './mutations.generated';
import moment from 'moment';
import { Runner } from '@generated/schema';
import { Blockquote, Button, validation } from '@queensland-running/qr-components';

type UpdateRunnerModalViewProps = {
  open: boolean;
  onClose: () => void;
  runner: Runner;
  setData: (runners: Runner) => void;
};

const UpdateRunnerModalView = ({ onClose, open, runner, setData }: UpdateRunnerModalViewProps) => {
  const [updateRunner] = useUpdateRunnerMutation({
    onCompleted: (data: UpdateRunnerMutation) => {
      if (data && data.runners && data.runners.updateRunner) {
        const runner: Runner = data.runners.updateRunner as Runner;
        setData(runner);
      }
      onClose();
    },
  });
  const {
    errors,
    isValid,
    values,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
    resetForm,
  } = useFormik({
    initialValues: {
      id: runner.id,
      bibNumber: runner.bibNumber,
      firstName: runner.firstName,
      lastName: runner.lastName,
      gender: runner.gender ? runner.gender : '',
      dateOfBirth: runner.dateOfBirth,
      club: runner.club ? runner.club : '',
    },
    validationSchema: object().shape({
      firstName: validation.qrFirstName,
      lastName: validation.qrLastName,
      bibNumber: validation.bibNumber,
      club: validation.club,
      dateOfBirth: validation.dateOfBirth,
    }),
    onSubmit: (values) => {
      updateRunner({
        variables: {
          input: {
            id: values.id,
            bibNumber: values.bibNumber ? parseInt(values.bibNumber) : null,
            firstName: values.firstName === '' ? null : values.firstName,
            lastName: values.lastName === '' ? null : values.lastName,
            club: values.club === '' ? null : values.club,
            dateOfBirth: moment(values.dateOfBirth!).format('YYYY-MM-DD'),
            gender: values.gender === '' ? null : values.gender,
          },
        },
      });
    },
  });
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        resetForm();
      }}
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <form onSubmit={handleSubmit}>
        <DialogTitle id="alert-dialog-title">Update Runner</DialogTitle>
        <DialogContent id="alert-dialog-description">
          <Grid item xs={12}>
            <Blockquote color={'secondary'}>
              <Typography noWrap>
                {values.firstName} {values.lastName}
              </Typography>
              <Typography variant={'subtitle2'}>
                <b>Bib Number:</b>&nbsp;{values.bibNumber}
                <br />
                <b>Date of Birth:</b>&nbsp;{values.dateOfBirth}
                <br />
                <b>Gender:</b>&nbsp;{values.gender}
                <br />
              </Typography>
            </Blockquote>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                error={!!(errors.club && touched.club)}
                label="Club/School/Independent"
                name="club"
                value={values.club}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.club && touched.club && errors.club}
                disabled={isSubmitting}
                margin="normal"
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            title='Close'
            color="primary"
            autoFocus
            onClick={() => {
              onClose();
              resetForm();
            }}/>
          <Button color="primary" type="submit" variant="contained" disabled={!isValid || isSubmitting} title='Update Runner'/>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export const UpdateRunnerModal = React.memo(UpdateRunnerModalView);
