import { Typography } from '@material-ui/core';
import React from 'react';
import { Modal } from '@queensland-running/qr-components';

type ConditionsOfEntryDialogProps = {
  onClose: () => void;
  open: boolean;
};

export const ConditionsOfEntryDialog = ({ open, onClose }: ConditionsOfEntryDialogProps) => (
  <Modal
    open={open}
    onClose={onClose}
    title="Conditions of Entry"
    primaryAction={onClose}
    primaryActionText="Close">
    <Typography>As an individual/parent, for myself and/or children in my care, I</Typography>
    <ul>
      <Typography>
        <li>accept sole responsibility to enter and/or start or continue in any race on the program;</li>
      </Typography>
      <Typography>
        <li>
          indemnify Queensland Running, the State Athletic Clubs Association Inc., its officers, officials and sponsors
          from any claim/s as a result of loss, injury, accident or incident of any kind arising from participation in
          any event;
        </li>
      </Typography>
      <Typography>
        <li>
          undertake to advise of any physical or medical conditions which may place my and/or their health or wellbeing,
          or the health or wellbeing of others in jeopardy;
        </li>
      </Typography>
      <Typography>
        <li>consent to the publication of my/our names and photographs for results and/or promotional purposes;</li>
      </Typography>
      <Typography>
        <li>
          will comply with Queensland Government COVID 19 regulations, and provide the required details of those present
          in our family group.
        </li>
      </Typography>
    </ul>
  </Modal>
);
