import React, { Component } from 'react';
import UserContext from '../UserContext';
import { Redirect, Route, RouteProps, withRouter } from 'react-router';

type PrivateRouteProps = RouteProps;
type PrivateRouteState = { loaded: boolean; isAuthenticated: boolean };
class PrivateRouteClass extends Component<PrivateRouteProps, PrivateRouteState> {
  state = {
    loaded: false,
    isAuthenticated: false,
  };
  static contextType = UserContext;

  componentDidMount() {
    // @ts-ignore
    this.unlisten = this.props.history.listen(() => {
      this.context.updateCurrentUser();
    });
  }

  componentWillUnmount() {
    // @ts-ignore
    this.unlisten();
  }

  render() {
    // @ts-ignore
    const { component: Component, ...rest } = this.props;
    const isAuthenticated = this.context.user && this.context.user.username ? true : false;
    const isLoaded = this.context.isLoaded;
    if (!isLoaded) return null;

    return (
      <Route
        {...rest}
        render={(props) => {
          return isAuthenticated ? (
            // @ts-ignore
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/auth',
              }}
            />
          );
        }}
      />
    );
  }
}

// @ts-ignore
export const PrivateRoute = withRouter<PrivateRouteProps>(PrivateRouteClass);
