import React, { useEffect, useState } from 'react';
import {LoadingComponent, PaymentSelection} from '@queensland-running/qr-components';
import { StepNavigator, StepProps } from '../event-registration-flow';
import { useRegisterPaymentForWeeklyCompetitionMutation } from '../mutations.generated';
import { useRegistration } from '@constants/registration-context';
import {
  ContactDetails,
  PaymentType,
  RockettRelaysRunnerDetails,
  WeeklyCompetitionRunnerDetails,
} from '@generated/schema';
import { useApolloClient } from '@apollo/client';
import {RockettRelaysRegistrationFragmentDoc, WeeklyRegistrationRegistrationFragmentDoc} from '../fragments.generated';

interface SquarePaymentProps {
  token: string;
}

const useRegisterForEvent = () => {
  const [selectedRunners] = useState<
    RockettRelaysRunnerDetails[] | WeeklyCompetitionRunnerDetails[]
  >([]);

  const [contactDetails, setContactDetails] = useState<ContactDetails | undefined>();
  const [price, setPrice] = useState<number>(0);
  const { registrationId, competitionRegistration__typename } = useRegistration();
  const client = useApolloClient();

  useEffect(() => {
    if (competitionRegistration__typename) {
      const registrationFragment = client.readFragment({
        id: `${competitionRegistration__typename}:${registrationId}`,
        fragment: {
          "WeeklyCompetitionRegistration": WeeklyRegistrationRegistrationFragmentDoc,
          "RockettRelaysRegistration": RockettRelaysRegistrationFragmentDoc
        }[competitionRegistration__typename!],
      });

      switch (competitionRegistration__typename) {
        case 'WeeklyCompetitionRegistration':
          setContactDetails(registrationFragment.contactDetails as ContactDetails);
          setPrice(
            ((registrationFragment.runnerDetails as WeeklyCompetitionRunnerDetails[]) || [])
              .map(({ item }) => {
                if (item) {
                  return parseInt(item.price ?? '0');
                }
                return 0;
              })
              .reduce((left, right) => left + right, 0) ?? 0,
          );

          break;
        case 'RockettRelaysRegistration':
          break;
      }
    }
  }, [competitionRegistration__typename, registrationId, client]);

  return { selectedRunners, contactDetails, price };
};
export const SelectPaymentStep = ({ handleNext, handleBack }: StepProps) => {
  const { registrationId, applicationId, locationId } = useRegistration();
  const { price, contactDetails } = useRegisterForEvent();
  const [registerPaymentForEvent] = useRegisterPaymentForWeeklyCompetitionMutation({
    onCompleted() {
      handleNext!();
    },
  });



  useEffect(() => {
    if (!registrationId) {
      const timer = setTimeout(() => {
        handleBack!()
      }, 1500)
      return () => clearTimeout(timer)
    }
  }, [registrationId, handleBack])

  if (!registrationId) {
    return (
      <>
        <br/>
        <LoadingComponent message="Confirming payment details..."/>
      </>
    );
  }

  const handleSubmit = (method: PaymentType, paymentProps?: SquarePaymentProps) => {
    registerPaymentForEvent({
      variables: {
        input: {
          locationId: locationId,
          registrationId: registrationId,
          payment: {
            method,
            price,
            ...paymentProps,
          },
        },
      },
    });
  };

  return (
    <>
      <PaymentSelection
        price={price}
        name="paymentMethod"
        handleSubmit={handleSubmit}
        disabled={false}
        bankTransfer={{
          reference: contactDetails?.lastName,
        }}
        // square={{
        //   applicationId,
        //   locationId,
        //   cardTokenizeResponseReceived: (token, buyer) => {
        //     switch (token.status) {
        //       case 'OK': {
        //         handleSubmit('SQUARE_PAYMENT', {
        //           token: token.token || '',
        //         });
        //         break;
        //       }
        //       default: {
        //         console.log('SOME ERROR', token.status);
        //       }
        //     }
        //   },
        //   createVerificationDetails: () => ({
        //     amount: `${price}`,
        //     billingContact: {
        //       familyName: contactDetails?.lastName ?? '',
        //       givenName: contactDetails?.firstName ?? '',
        //     },
        //     currencyCode: 'AUD',
        //     intent: 'CHARGE',
        //   }),
        // }}
      />
      <br/>
      <StepNavigator handleBack={handleBack} backText={'Back'} />
    </>
  );
};
