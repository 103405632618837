/* eslint-disable */
import * as Types from '../../../@generated/schema.d';

import { gql } from 'graphql.macro';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/client/react/components';
import * as ApolloReactHoc from '@apollo/client/react/hoc';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FetchCompetitionsQueryVariables = Types.Exact<{
  year: Types.Scalars['String'];
}>;

export type FetchCompetitionsQuery = { readonly __typename?: 'Query' } & {
  readonly competitions?: Types.Maybe<
    { readonly __typename?: 'CompetitionsQueryResponse' } & Pick<
      Types.CompetitionsQueryResponse,
      'availableYears' | 'selectedYear'
    > & {
        readonly competitions?: Types.Maybe<
          ReadonlyArray<
            | ({ readonly __typename: 'AllSchoolsChampionships' } & Pick<
                Types.AllSchoolsChampionships,
                'id' | 'day' | 'agenda'
              >)
            | ({ readonly __typename: 'NoCompetition' } & Pick<Types.NoCompetition, 'id' | 'day' | 'agenda'>)
            | ({ readonly __typename: 'RockettRelays' } & Pick<
                Types.RockettRelays,
                'venueId' | 'results' | 'program' | 'status' | 'id' | 'day' | 'agenda'
              > & {
                  readonly venue?: Types.Maybe<
                    { readonly __typename?: 'Venue' } & Pick<
                      Types.Venue,
                      'archived' | 'name' | 'street' | 'url' | 'imageUrl'
                    >
                  >;
                })
            | ({ readonly __typename: 'WeeklyCompetition' } & Pick<
                Types.WeeklyCompetition,
                'venueId' | 'program' | 'status' | 'results' | 'id' | 'day' | 'agenda'
              > & {
                  readonly venue?: Types.Maybe<
                    { readonly __typename?: 'Venue' } & Pick<
                      Types.Venue,
                      'name' | 'archived' | 'street' | 'url' | 'imageUrl'
                    >
                  >;
                })
            | ({ readonly __typename: 'WinterChampionships' } & Pick<
                Types.WinterChampionships,
                'venueId' | 'program' | 'status' | 'results' | 'id' | 'day' | 'agenda'
              > & {
                  readonly venue?: Types.Maybe<
                    { readonly __typename?: 'Venue' } & Pick<
                      Types.Venue,
                      'name' | 'archived' | 'street' | 'url' | 'imageUrl'
                    >
                  >;
                })
          >
        >;
      }
  >;
};

export type FetchCompetitionQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type FetchCompetitionQuery = { readonly __typename?: 'Query' } & {
  readonly competition?: Types.Maybe<
    | ({ readonly __typename?: 'AllSchoolsChampionships' } & Pick<
        Types.AllSchoolsChampionships,
        'id' | 'day' | 'agenda'
      >)
    | ({ readonly __typename?: 'NoCompetition' } & Pick<Types.NoCompetition, 'id' | 'day' | 'agenda'>)
    | ({ readonly __typename?: 'RockettRelays' } & Pick<
        Types.RockettRelays,
        'venueId' | 'program' | 'status' | 'id' | 'day' | 'agenda'
      > & {
          readonly venue?: Types.Maybe<
            { readonly __typename?: 'Venue' } & Pick<
              Types.Venue,
              'displayName' | 'name' | 'street' | 'url' | 'imageUrl' | 'googleMapsSrc'
            >
          >;
          readonly fees?: Types.Maybe<
            { readonly __typename?: 'RockettRelaysFees' } & {
              readonly ONE_HOUR_RELAY: { readonly __typename?: 'RockettRelaysRelayFee' } & Pick<
                Types.RockettRelaysRelayFee,
                'weeklyCompetitor' | 'nonWeeklyCompetitor'
              >;
              readonly TWO_HOUR_RELAY: { readonly __typename?: 'RockettRelaysRelayFee' } & Pick<
                Types.RockettRelaysRelayFee,
                'weeklyCompetitor' | 'nonWeeklyCompetitor'
              >;
            }
          >;
        })
    | ({ readonly __typename?: 'WeeklyCompetition' } & Pick<
        Types.WeeklyCompetition,
        'status' | 'results' | 'program' | 'id' | 'day' | 'agenda'
      > & {
          readonly venue?: Types.Maybe<
            { readonly __typename?: 'Venue' } & Pick<
              Types.Venue,
              'displayName' | 'name' | 'street' | 'url' | 'imageUrl' | 'googleMapsSrc'
            >
          >;
        })
    | ({ readonly __typename?: 'WinterChampionships' } & Pick<
        Types.WinterChampionships,
        'venueId' | 'program' | 'status' | 'id' | 'day' | 'agenda'
      > & {
          readonly venue?: Types.Maybe<
            { readonly __typename?: 'Venue' } & Pick<
              Types.Venue,
              'displayName' | 'name' | 'street' | 'url' | 'imageUrl' | 'googleMapsSrc'
            >
          >;
        })
  >;
};

export const FetchCompetitionsDocument = gql`
  query fetchCompetitions($year: String!) {
    competitions(year: $year) {
      availableYears
      selectedYear
      competitions {
        __typename
        id
        day
        agenda
        ... on WeeklyCompetition {
          venueId
          venue {
            name
            archived
            street
            url
            imageUrl
          }
          program
          status
          results
        }
        ... on RockettRelays {
          venueId
          venue {
            archived
            name
            street
            url
            imageUrl
          }
          results
          program
          status
        }
        ... on WinterChampionships {
          venueId
          venue {
            name
            archived
            street
            url
            imageUrl
          }
          program
          status
          results
        }
      }
    }
  }
`;
export type FetchCompetitionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<FetchCompetitionsQuery, FetchCompetitionsQueryVariables>,
  'query'
> &
  ({ variables: FetchCompetitionsQueryVariables; skip?: boolean } | { skip: boolean });

export const FetchCompetitionsComponent = (props: FetchCompetitionsComponentProps) => (
  <ApolloReactComponents.Query<FetchCompetitionsQuery, FetchCompetitionsQueryVariables>
    query={FetchCompetitionsDocument}
    {...props}
  />
);

export type FetchCompetitionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<FetchCompetitionsQuery, FetchCompetitionsQueryVariables>;
} &
  TChildProps;
export function withFetchCompetitions<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FetchCompetitionsQuery,
    FetchCompetitionsQueryVariables,
    FetchCompetitionsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FetchCompetitionsQuery,
    FetchCompetitionsQueryVariables,
    FetchCompetitionsProps<TChildProps, TDataName>
  >(FetchCompetitionsDocument, {
    alias: 'fetchCompetitions',
    ...operationOptions,
  });
}

/**
 * __useFetchCompetitionsQuery__
 *
 * To run a query within a React component, call `useFetchCompetitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCompetitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCompetitionsQuery({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function useFetchCompetitionsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchCompetitionsQuery, FetchCompetitionsQueryVariables>,
) {
  return Apollo.useQuery<FetchCompetitionsQuery, FetchCompetitionsQueryVariables>(
    FetchCompetitionsDocument,
    baseOptions,
  );
}
export function useFetchCompetitionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchCompetitionsQuery, FetchCompetitionsQueryVariables>,
) {
  return Apollo.useLazyQuery<FetchCompetitionsQuery, FetchCompetitionsQueryVariables>(
    FetchCompetitionsDocument,
    baseOptions,
  );
}
export type FetchCompetitionsQueryHookResult = ReturnType<typeof useFetchCompetitionsQuery>;
export type FetchCompetitionsLazyQueryHookResult = ReturnType<typeof useFetchCompetitionsLazyQuery>;
export type FetchCompetitionsQueryResult = Apollo.QueryResult<FetchCompetitionsQuery, FetchCompetitionsQueryVariables>;
export const FetchCompetitionDocument = gql`
  query fetchCompetition($id: String!) {
    competition(id: $id) {
      id
      day
      agenda
      ... on RockettRelays {
        venueId
        venue {
          displayName
          name
          street
          url
          imageUrl
          googleMapsSrc
        }
        program
        status
        fees {
          ONE_HOUR_RELAY {
            weeklyCompetitor
            nonWeeklyCompetitor
          }
          TWO_HOUR_RELAY {
            weeklyCompetitor
            nonWeeklyCompetitor
          }
        }
      }
      ... on WinterChampionships {
        venueId
        venue {
          displayName
          name
          street
          url
          imageUrl
          googleMapsSrc
        }
        program
        status
      }
      ... on WeeklyCompetition {
        status
        results
        venue {
          displayName
          name
          street
          url
          imageUrl
          googleMapsSrc
        }
        program
      }
    }
  }
`;
export type FetchCompetitionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<FetchCompetitionQuery, FetchCompetitionQueryVariables>,
  'query'
> &
  ({ variables: FetchCompetitionQueryVariables; skip?: boolean } | { skip: boolean });

export const FetchCompetitionComponent = (props: FetchCompetitionComponentProps) => (
  <ApolloReactComponents.Query<FetchCompetitionQuery, FetchCompetitionQueryVariables>
    query={FetchCompetitionDocument}
    {...props}
  />
);

export type FetchCompetitionProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<FetchCompetitionQuery, FetchCompetitionQueryVariables>;
} &
  TChildProps;
export function withFetchCompetition<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FetchCompetitionQuery,
    FetchCompetitionQueryVariables,
    FetchCompetitionProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    FetchCompetitionQuery,
    FetchCompetitionQueryVariables,
    FetchCompetitionProps<TChildProps, TDataName>
  >(FetchCompetitionDocument, {
    alias: 'fetchCompetition',
    ...operationOptions,
  });
}

/**
 * __useFetchCompetitionQuery__
 *
 * To run a query within a React component, call `useFetchCompetitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCompetitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCompetitionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchCompetitionQuery(
  baseOptions: Apollo.QueryHookOptions<FetchCompetitionQuery, FetchCompetitionQueryVariables>,
) {
  return Apollo.useQuery<FetchCompetitionQuery, FetchCompetitionQueryVariables>(FetchCompetitionDocument, baseOptions);
}
export function useFetchCompetitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchCompetitionQuery, FetchCompetitionQueryVariables>,
) {
  return Apollo.useLazyQuery<FetchCompetitionQuery, FetchCompetitionQueryVariables>(
    FetchCompetitionDocument,
    baseOptions,
  );
}
export type FetchCompetitionQueryHookResult = ReturnType<typeof useFetchCompetitionQuery>;
export type FetchCompetitionLazyQueryHookResult = ReturnType<typeof useFetchCompetitionLazyQuery>;
export type FetchCompetitionQueryResult = Apollo.QueryResult<FetchCompetitionQuery, FetchCompetitionQueryVariables>;
