import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {toggles} from './features';

const appConfig = {
  toggles, constants: {
    appTitle: "Queensland Running",
    currentYear: "2024"
  },
}
const ConfigContext = createContext<AppConfig>(appConfig);

export interface AppConfig {
  toggles: typeof toggles;
  constants: {
    appTitle: string
    currentYear: string
  }
}

const mapToConfig = (windowConfig: any): AppConfig => {
  return {
    ...appConfig,
    toggles: {
      ...toggles,
      ...windowConfig.toggles
    }
  };
};

const LoadConfig = () => {
  const [config, setConfig] = useState<AppConfig>(appConfig);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    // @ts-ignore
    const windowConfig = window['config'];

    if (windowConfig) {
      try {
        setConfig(mapToConfig(windowConfig));
      } catch (e) {
        setError(true);
        setLoading(false);
      }
    }
  }, []);
  return {config, loading, error};
};

export const useConfig = () => {
  const config = useContext(ConfigContext);

  if (!config) {
    throw new Error('Configuration should already be loaded');
  }

  return config;
};

const ConfigProvider = ({children}: { children: ReactNode }) => {
  const {config, loading, error} = LoadConfig();
  return (
    <ConfigContext.Provider value={config}>
      {loading && <div>Loading</div>}
      {error && <div>Error loading configuration</div>}
      {!loading && !error && children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;
