import axios, {AxiosInstance, AxiosResponse} from "axios";
import React, {createContext, ReactNode, useContext} from 'react';

type APIEndpoints = {
    register: (registrationData: any) => Promise<AxiosResponse<any, any>>,
    calendar: (year: string) => Promise<AxiosResponse<any, any>>,
    registrations: (competitionId: string) => Promise<AxiosResponse<any, any>>,
};
const generateEndpoints = (client: AxiosInstance | undefined): APIEndpoints => {
    if (!client) {
        return {
            register: () => Promise.reject(),
            registrations: () => Promise.reject(),
            calendar: () => Promise.reject()
        }
    }
    return {
        register: (registrationData: any) => client.post('register', registrationData).then((res) => res.data),
        calendar: (year: any) => client.get(`calendar/${year}`).then((res) => res.data),
        registrations: (competitionId: string) => client.get(`registrations/${competitionId}`).then((res) => res.data)
    }
}

const QueenslandRunningAPIContext = createContext<QueenslandRunningConfig>({
    client: undefined
});

export interface QueenslandRunningConfig {
    client: AxiosInstance | undefined;
}

export const useQueenslandRunningAPI = (): APIEndpoints => {
    const config = useContext(QueenslandRunningAPIContext);

    if (!config) {
        throw new Error('Configuration should already be loaded');
    }

    return generateEndpoints(config.client)
};

const QueenslandRunningAPIProvider = ({children}: { children: ReactNode }) => {
    const client = axios.create({
        baseURL: "http://localhost:9876/dev/",
        headers: {
            'x-api-key': 'd41d8cd98f00b204e9800998ecf8427e'
        }
    });

    return <QueenslandRunningAPIContext.Provider value={{client}}>
        {children}
    </QueenslandRunningAPIContext.Provider>;
};

export default QueenslandRunningAPIProvider;
