import React, {FunctionComponent, useState} from 'react';
import {useFormik} from 'formik';
import {boolean, object, string} from 'yup';
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core';
import {AnchorLink} from '@queensland-running/qr-components';
import {Contact} from '@components';
import {ConditionsOfEntryDialog} from '../../modals/terms-modal';
import {Section} from '@components/section/section';
import {Routes} from '@constants';
import {toggles} from '@constants/features';
import {CompetitionType} from "./multi-registration-flow";
import {useTranslation} from "react-i18next";
import v4 from "uuid/v4";
//Use config manager instead of importing toggles

const validationSchema = object().shape({
    // Contact Details
    email: string()
        .email('Invalid email')
        .required('An email address is required'),
    firstName: string().required('Required'),
    lastName: string().required('Required'),
    // Runners
    agree: boolean()
        .required('The conditions of entry must be accepted.')
        .oneOf([true], 'The conditions of entry must be accepted.'),
    phoneNumber: toggles.covid ? string().required('Required - due to COVID-19 guidelines') : string().notRequired(),
});

interface SubRegistrationFlowProps {
    onSubmit: any;
}

const SingleRegistration = ({
                                onSubmit,
                                competition, details: Details
                            }: SubRegistrationFlowProps & React.PropsWithChildren<{
    competition: any
    details: FunctionComponent<{ competition: CompetitionType }>
}>) => {
    const {t} = useTranslation('common')
    const [conditionsOfEntryModalOpen, setConditionsOfEntryModalOpen] = useState<boolean>(false);
    const handleOpen = () => {
        setConditionsOfEntryModalOpen(true);
    };
    const handleClose = () => {
        setConditionsOfEntryModalOpen(false);
    };
    const {errors, values, touched, handleChange, handleBlur, handleSubmit, isSubmitting} = useFormik({
        initialValues: {
            email: '',
            firstName: '',
            lastName: '',
            ...(toggles.covid && {
                phoneNumber: '',
            }),
            agree: false,
        },
        validationSchema,
        onSubmit: (values) => {
            onSubmit({
                event: {
                    id: competition.id,
                    date: competition.day,
                    agenda: competition.agenda,
                    venue: {
                        url: competition.venue.url,
                        name: competition.venue.name
                    }
                },
                username: values.email && values.email.trim(),
                registrationType: 'INDIVIDUAL',
                contact: {
                    firstName: values.firstName && values.firstName.trim(),
                    lastName: values.lastName && values.lastName.trim(),
                    ...(toggles.covid && {
                        phoneNumber: values.phoneNumber,
                    }),
                },
                runners: [{
                    id: v4(),
                    firstName: 'James',
                    lastName: 'Running'
                }]
            });
        },
    });

    return (
        <form onSubmit={handleSubmit}>
            <br/>
            <Section verticalSpacing>
                <Typography>If you are registering to this event as an individual, please use the form
                    below.</Typography>
                <Typography>
                    If you are registering multiple runners to an event, either send us an email at{' '}
                    <Contact name="qr" type={'emailAddress'}/> or complete and bring the Weekly Entry Form along with
                    you on the
                    day of the event.
                </Typography>
                <AnchorLink to={`${Routes.registration}#prepaidRegistration`}>
                    <Button color="primary" size="small">
                        {t('competition.buttons.registrationDetails')}
                    </Button>
                </AnchorLink>
            </Section>
            <Typography variant="h4">Contact details</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <TextField
                        required
                        error={!!(errors.email && touched.email)}
                        label="Email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.email && touched.email && errors.email}
                        disabled={isSubmitting}
                        margin="normal"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        error={!!(errors.firstName && touched.firstName)}
                        label="First Name"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.firstName && touched.firstName && errors.firstName}
                        disabled={isSubmitting}
                        margin="normal"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        error={!!(errors.lastName && touched.lastName)}
                        label="Last Name"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={errors.lastName && touched.lastName && errors.lastName}
                        disabled={isSubmitting}
                        margin="normal"
                        fullWidth
                    />
                </Grid>

                {toggles.covid && (
                    <Grid item xs={12} sm={6}>
                        <TextField
                            error={!!(errors.phoneNumber && touched.phoneNumber)}
                            label="Phone Number"
                            name="phoneNumber"
                            value={values.phoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
                            disabled={isSubmitting}
                            margin="normal"
                            required
                            fullWidth
                        />
                    </Grid>
                )}
                <Details competition={competition}/>
                <Grid item xs={12}>
                    <FormControl error={!!(errors.agree && touched.agree)} required>
                        <FormControlLabel
                            onChange={handleChange}
                            checked={values.agree}
                            name="agree"
                            value={values.agree}
                            disabled={isSubmitting}
                            onBlur={handleBlur}
                            control={<Checkbox/>}
                            label="I agree to the conditions of entry"
                        />
                    </FormControl>
                    {errors.agree && touched.agree &&
                        <FormHelperText error={!!errors.agree}>{errors.agree}</FormHelperText>}
                </Grid>

                <Grid item xs={12}>
                    <Typography onClick={handleOpen} style={{textDecoration: 'underline', cursor: 'pointer'}}
                                gutterBottom>
                        {t('competition.buttons.conditionsOfEntry')}
                    </Typography>
                    <br/>
                    <Button color="primary" type="submit" variant="contained" disabled={isSubmitting}>
                        {t('competition.buttons.register')}
                    </Button>
                    <ConditionsOfEntryDialog open={conditionsOfEntryModalOpen} onClose={handleClose}/>
                </Grid>
            </Grid>
        </form>
    );
};

export {SingleRegistration};
