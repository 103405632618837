import React from 'react';
import {compose} from 'recompose';
import {Button, Typography} from '@material-ui/core';
import {Section} from "@components/section/section";
import {ProgramTable, UnstyledA} from "@queensland-running/qr-components";
import {Contact, usePrograms} from "@components";
import {CompetitionForms} from "../event-forms";

const enhance = compose(React.memo);

const WinterChampionshipsView = () => {
    const {getProgram} = usePrograms()
    const program = getProgram("Winter Championships")
    return (
        <>
            <Typography variant="h5" color={'secondary'}>
                Program
            </Typography>
            <ProgramTable programItems={program || []}/>
            <br/>
            <Typography variant="subtitle2">
                <b>* Masters Men Age Groups: 40-49 years, 50+ years</b>
            </Typography>
            <Typography variant="subtitle2">
                <b>* Masters Women Age Groups 35-44 years, 45+ years</b>
            </Typography>
            <br/>
            <br/>
            <Section verticalSpacing>
                <Typography>
                    <UnstyledA href={'/assets/pdfs/results/Results 2021 WC.pdf'} target="_blank"
                               rel="noopener noreferrer">
                        <Button color={'secondary'} variant={'contained'}>
                            2021 Winter Championship Results
                        </Button>
                    </UnstyledA>
                </Typography>

                <Typography variant="h3">Update: 17th July 2021 - Winter Championships</Typography>
                <Typography>
                    This year due to the uncertainty with regards to health issues we have decided to make a change with
                    regards
                    to the Winter Championships on 24 July.
                </Typography>
                <Typography>
                    We are running our normal weekly program commencing at 2.00 p.m. and each age group championship
                    race will be
                    included throughout the afternoon. As usual, all runners may participate in each race but for
                    example in the
                    500m race starting at 2.00 p.m. the 4, 5 and 6 year boys and girls will be the only runners eligible
                    for a
                    medal. So here are the details for 24 July.
                </Typography>
                <Typography>
                    2.00 p.m. – 500m – Medals for 4, 5 and 6 year boys and girls. Any other runners may choose to run.
                </Typography>
                <Typography>2.15 p.m. – 1500m – any age runners (no medals)</Typography>
                <Typography>
                    2.35 p.m. – 3000/5000m – Medals for 11, 12, and 13 year boys and girls for 3000m event only. Any
                    other runners
                    may choose to run.
                </Typography>
                <Typography>
                    3.00 p.m. – 4000/6000/8000m – Medals for 14, 15, 16, and 17 year boys and girls and Open and Masters
                    Men and
                    Women.
                </Typography>
                <Typography>
                    3.35 p.m. - 2000m – Medals for 9 and 10 years boys and girls and Open and Masters Men and Women. Any
                    other
                    runners may choose to run.
                </Typography>
                <Typography>
                    3.50 p.m. – 1000m – Medals for 7 and 8 years boys and girls. Any other runners may choose to run.
                </Typography>
                <Typography>
                    On the 31 st July we will have additional championship races for our Open and Masters runners in the
                    1000m and
                    3000m category.
                </Typography>
                <Typography>
                    If you have run in our competition this season, then you will pay the running fee that you normally
                    pay. Those
                    runners who paid for a Fully Paid Season in 2020 and 2021, the championships are included in that
                    fee. Other
                    runners who wish to run, the cost will be $7.00.
                </Typography>
                <Typography>
                    Please have your entries in by Friday night, either through the website or email your details to us.
                    This will
                    assist in running the afternoon smoothly.
                </Typography>
                <Typography>If you have any questions please don't hesitate to call 0418 113377.</Typography>
                <Typography>
                    As usual, even though the races are run together, there are medals
                    for each age group, e.g. six medals for the first race.
                </Typography>
                <Typography>
                    PLEASE NOTE: The 1000m, 3000m and 4000m Open and Masters
                    Championship Events are to be held on Saturday 27 July, the final
                    day of the Season as the normal part of the program.
                </Typography>
                <Typography>
                    We would appreciate that entries are forwarded to:
                    <Contact name="qr" type="emailAddress"/> prior to the event.
                </Typography>
                <Typography>
                    Please don't hesitate to contact us if you have any enquiries.
                </Typography>
                <Typography>
                    Please note: At the Winter Championship athletes can only compete
                    in their own age group event, unless the distance is different
                    from their school championship distance. Only athletes 18 years+
                    can enter the Open age group races.
                </Typography>
            </Section>

            <Section verticalSpacing>
                {/*<Typography variant="h3" color="primary">*/}
                {/*  Entry Fees*/}
                {/*</Typography>*/}
                {/*<Typography>*/}
                {/*  Entry fee must be paid in person on or before Championship day for*/}
                {/*  entry to be confirmed and accepted.*/}
                {/*</Typography>*/}
                {/*<Typography>*/}
                {/*  Age is taken as age on the day of the championship.*/}
                {/*</Typography>*/}

                {/*<Typography variant="h4">Registered Athletes</Typography>*/}
                {/*<Blockquote color="primary">*/}
                {/*  Athletes who have paid the $12 season registration fee or*/}
                {/*  prepayment option at the beginning of the season)*/}
                {/*</Blockquote>*/}
                {/*<Table size="small">*/}
                {/*  <TableHead>*/}
                {/*    <TableRow>*/}
                {/*      <TableCell>Age</TableCell>*/}
                {/*      <TableCell>Season Registered Athletes</TableCell>*/}
                {/*      <TableCell>Regular Casual Runners</TableCell>*/}
                {/*    </TableRow>*/}
                {/*  </TableHead>*/}
                {/*  <TableBody>*/}
                {/*    <TableRow>*/}
                {/*      <TableCell>10 & Under</TableCell>*/}
                {/*      <TableCell>$2.00</TableCell>*/}
                {/*      <TableCell>$3.00</TableCell>*/}
                {/*    </TableRow>*/}
                {/*    <TableRow>*/}
                {/*      <TableCell>11-16 years</TableCell>*/}
                {/*      <TableCell>$3.00</TableCell>*/}
                {/*      <TableCell>$4.00</TableCell>*/}
                {/*    </TableRow>*/}
                {/*    <TableRow>*/}
                {/*      <TableCell>17+ years</TableCell>*/}
                {/*      <TableCell>$4.00</TableCell>*/}
                {/*      <TableCell>$5.00</TableCell>*/}
                {/*    </TableRow>*/}
                {/*  </TableBody>*/}
                {/*</Table>*/}
                {/*<br />*/}
                {/*<Typography>*/}
                {/*  <b>*/}
                {/*    Athletes without a Queensland Running race number: $7.00 (all*/}
                {/*    ages)*/}
                {/*  </b>*/}
                {/*</Typography>*/}
            </Section>
            {/*<PaymentInfoSection cashOptionAvailable directDebitOptionAvailable />*/}
            <Section verticalSpacing>
                <Typography variant="h3" color="primary">
                    How to enter
                </Typography>
                <Typography>
                    <b>
                        Email <Contact name="qr" type="emailAddress"/> with the
                        following:
                    </b>
                </Typography>
                <Typography>
                    <i>Name, School/Club, Gender, DOB, Age on day, Distance</i>
                </Typography>
                <Typography>
                    Lengthy delays can be expected if entering on the day of the
                    championship.
                </Typography>
                <Typography>
                    Age is taken as age on the day of the championship.
                </Typography>
                <Typography>
                    <b>Registration Table:</b> Entries will be accepted at the
                    registration table on Championship day.
                </Typography>
                <Typography>
                    Lengthy delays can sometimes be experienced at the registration
                    desk. Preferred option is to register by email. Please complete an
                    entry form at the registration tent, or download the form below,
                    fill it in and bring it with you.
                </Typography>
                <CompetitionForms agenda={'Winter Championships'}/>
                <Typography>
                    Runners wishing to enter after 9.30am on championship day, will
                    need to do so at the registration table at Pine Rivers Park.
                </Typography>
            </Section>
        </>
    );
};

export const WinterChampionships = enhance(WinterChampionshipsView);
